<template>
    <div aria-modal="true" aria-label="user Details"
        class=" q-animatable elevate-5 details-container  q-display-flex-column " ref="detailsDialog">
        <progress-overlay-element ref="q_overlay"></progress-overlay-element>
        <DialogComponent @accepted="deleteUserAccount" ref="dialog_component"></DialogComponent>
        <div class="drawer-header  q-display-flex-row q-align-stretch">
            <h3 style="margin: 0px;width: 100%;">User Details</h3>
            <button data-can-be-disabled @click="close" class=" q-display-flex-column q-center q-flex-center-items"
                title="close">
                <i class="ri-close-circle-line q-align-self-right"></i>
            </button>

        </div>
        <div class="q-divider"></div>

        <div class="drawer-content-container">

            <div class=" q-layout-container q-progress-overlay-container content-container">
                <div class="q-layout-row  child-content-container  group-title-container">
                    <div
                        class="content-container q-col-sm-12 q-col-md-16 q-col-lg-6 q-col-xl-6 q-display-flex-column q-text-container">
                        <p>Details</p>
                    </div>
                </div>
                <div v-for="(detail) in userDetails" :key="detail.key" class="q-layout-row  child-content-container">
                    <div
                        class=" content-container q-col-sm-12 q-col-md-16 q-col-lg-6 q-col-xl-6 q-display-flex-column q-text-container">
                        <h4 style="margin: 0;">{{ detail.title }}<span>:</span></h4>
                    </div>
                    <div
                        class=" content-container q-col-sm-12 q-col-md-16 q-col-lg-6 q-col-xl-6 q-display-flex-column q-text-container">
                        <p style="margin: 0;">{{ detail.value }}</p>
                    </div>
                </div>
                <!--Controls-->
                <div class="q-layout-row  child-content-container  group-title-container">
                    <div
                        class="content-container q-col-sm-12 q-col-md-16 q-col-lg-6 q-col-xl-6 q-display-flex-column q-text-container">
                        <p>Controls</p>
                    </div>
                </div>
                <div class="q-layout-row  child-content-container  ">
                    <div
                        class="content-container q-col-sm-12 q-col-md-16 q-col-lg-6 q-col-xl-6 q-display-flex-column q-text-container">
                        <button data-can-be-disabled @click="deleteUser"
                            class="delete-button q-button q-display-flex-row q-center q-flex-center-items "><i
                                class="ri-delete-bin-6-line"></i>
                            <p>Delete User</p>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { showMessage, toggleDisabableElements } from "../../../public/js/utils.js"
import DialogComponent from "./DialogComponent.vue"

import axios from "axios";
axios.defaults.withCredentials = true;
export default {
    name: "NavigationBarComponent",
    components: {
        DialogComponent
    },
    data() {
        return {
            userDetails: [],
            currentUserId: ""
        }
    },
    methods: {
        show: async function () {
            var userData = this.$store.getters["current/currentUser"]
            this.$refs.detailsDialog.classList.add("showDrawer")

            let url = `${this.$store.getters["urls/adminBaseUrl"]}/users/${userData.objectId}`;
            try {
                this.showOverlay(true)
                // fetch user details
                var result = await axios.get(url);
                console.log("my reslr asdffs f " + JSON.stringify(result))
                this.userDetails = []
                var resultData = result.data
                for (var key in resultData) {
                    var value = resultData[key];
                    if (key === "objectId") {
                        this.currentUserId = value
                    }
                    if (key === "userPhotoUrl" && value.url != undefined) {
                        this.userDetails.push({
                            "key": "",
                            "title": "Photo",
                            "value": value.url
                        })
                    } else {
                        this.userDetails.push({
                            "key": "",
                            "title": key,
                            "value": value
                        })
                    }

                }
                this.userDetails.push({
                    "key": "",
                    "title": "Available Points",
                    "value": "0pts"
                })
                this.userDetails.push({
                    "key": "",
                    "title": "Used points",
                    "value": "0pts"
                })
                this.userDetails.push({
                    "key": "",
                    "title": "Recharged Points",
                    "value": "0pts"
                })
                // fetch user role
                url = `${this.$store.getters["urls/adminBaseUrl"]}/users/role/${userData.objectId}`;
                result = await axios.get(url);

                console.log("my resilsdf asf as f " + JSON.stringify(result))
                this.showOverlay(false)

                resultData = result.data[0]

                if (resultData && resultData.role) {
                    this.userDetails.push({
                        "key": "",
                        "title": "Role",
                        "value": resultData.role
                    })
                }

            } catch (error) {
                this.showOverlay(false)
                console.log("file to get user daisdfs " + error)
                showMessage("Something went wrong, please try again", true)
            }
        },
        close: function () {
            this.$refs.detailsDialog.classList.remove("showDrawer")
        },

        showOverlay: function (show) {
            this.$refs.q_overlay.run = show
        }
        ,
        deleteUser: function () {
            this.$refs.dialog_component.show(undefined, { "title": "Delete", "description": "Are you sure you want to delete this account?" })
        },
        deleteUserAccount: async function () {
            try {
                this.showOverlay(true)
                toggleDisabableElements(true)
                if (!this.$refs.detailsDialog.classList.contains("showDrawer")
                ) {
                    this.$refs.detailsDialog.classList.add("showDrawer")
                }
                var userData = this.$store.getters["current/currentUser"]

                var result = await axios.post(`${this.$store.getters["urls/adminBaseUrl"]}/users/delete/${userData.objectId}`)
                console.log("my reslt sfa sfas f " + JSON.stringify(result))
                if (result.data.msg == "ok") {
                    this.close()
                    this.$emit('userDeleted', '')
                } else {

                    showMessage("Failed to delete user", true)
                }
                toggleDisabableElements(false)
                this.showOverlay(false)

            } catch (error) {
                this.showOverlay(false)
                toggleDisabableElements(false)
                showMessage("Failed to delete user", true)
            }
        }
    }
};
</script>
<style scoped>
.showDrawer {
    width: 400px !important;
    opacity: 1 !important;
}

.details-container {
    position: fixed;
    background-color: white;
    height: 100vh;
    width: 0;
    right: 0;
    top: 0;
    padding: 0px;
}

.drawer-header button:hover {
    background-color: var(--nav-current)
}

.drawer-header button {
    border-radius: 32px;
    line-height: 1;
    height: 32px;
    width: 10%;
}

.drawer-header button i {
    font-size: 1.4em;
}

.drawer-header {
    height: 32px;
    padding: 16px;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 20px;
}

.q-divider {
    margin-top: 16px;
    margin-bottom: 0px;
}

.drawer-content-container {
    height: 100%;
    width: 100%;
    overflow: auto;
}



.child-content-container p,
.child-content-container h4 {
    margin: 0;
}

.child-content-container h4 {
    font-family: main_font_bold;
    font-size: .9em;
}

.group-title-container {
    background-color: var(--theme-color-navbar-bg);

}

.q-layout-row {
    width: 100%;
}

.q-button {
    width: 100%;
    background-color: var(--q_red);
    color: white !important;
}

.q-button p {
    color: white;
    margin-left: 8px
}
</style>
