export default {
  namespaced: true,
  state: {
    baseUrl: "http://api.gowwr.com", // "http://api.gowwr.com", //"http://gowwr.asuscomm.cn:20003", //"http://localhost:8000",
    userStorageKey: "gw_user"
  },

  getters: {
    baseUrl: function (state) {
      return `${state.baseUrl}`;
    },
    userStorageKey: function (state) {
      return `${state.userStorageKey}`;
    },
    adminBaseUrl: function (state) {
      return `${state.baseUrl}/admin`;
    }
  }
};
