<template>
    <div class="q_user_container">
        <div class="q-layout-container ">
            <div class="q-layout-row">
                <!--User points-->
                <div
                    class="q-col-sm-12 q-col-md-4 q-col-lg-4 q-col-xl-4 q-display-flex-column q-text-container flow-center q-center">
                    <div class="q-profile-photo-container q-display-flex-column q-align-stretch">
                        <div class="component-divider vertical-divider"></div>
                        <div class="component-divider horizontal-divider"></div>
                        <div class=" q-display-flex flow-center q-center">
                            <h2>{{ format(userPoints) }}</h2>

                        </div>

                        <div class=" q-display-flex-column q-center flow-center ">
                            <p>Available points</p>

                        </div>
                    </div>
                </div>
                <!--User rechaged pouned-->
                <div
                    class="q-col-sm-12 q-col-md-4 q-col-lg-4 q-col-xl-4 q-display-flex-column q-text-container flow-center q-center">
                    <div class="q-profile-photo-container q-display-flex-column  q-align-stretch flow-center q-center">
                        <div class=" q-display-flex flow-center q-center ">
                            <h2>{{ format(consumedPoints) }}</h2>
                        </div>

                        <div class=" q-display-flex-column  flow-center q-center">
                            <p>Total used points</p>

                        </div>
                        <div class="component-divider vertical-divider"></div>
                        <div class="component-divider horizontal-divider"></div>
                    </div>
                </div>
                <!--User toatl tones-->
                <div
                    class="q-col-sm-12 q-col-md-4 q-col-lg-4 q-col-xl-4 q-display-flex-column q-text-container flow-center q-center">
                    <div class="q-profile-photo-container q-display-flex-column q-align-stretch">
                        <div class=" q-display-flex-column flow-center q-center">
                            <h2>{{ 0 }}</h2>

                        </div>

                        <div class="q-display-flex-column q-center flow-center">
                            <p>Total Recharged Points</p>

                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>

import { numberWithCommas } from "../../public/js/utils.js"
export default {
    name: "UserPoints",
    computed: {
        userPoints() {
            return this.$store.getters["user/points"];
        },
        consumedPoints() {
            let data = this.$store.getters["user/user"];
            if (data && data.usedPoints)
                return data.usedPoints
            return 0;
        },
    },
    methods: {
        format: function (value) {
            return numberWithCommas(value)
        }
    }
    // data: function () {
    //   return {
    //     loggedIn: false,
    //     ready: false,
    //   };
    // },

    // mounted: function () {
    //   var url = `${this.$store.getters["urls/baseUrl"]}/register/`;
    //   console.log("is sdfoss asof asof " + url);
    //   console.log(
    //     "is sdf asof asf as = = = " + this.$store.getters["urls/userStorageKey"]
    //   );

    //   console.log("sf as fasf " + this.$store.getters["user/username"]);

    //   // redirect user to profile if they try to access this page when they already logged in
    //   const loggedInUser = localStorage.getItem(
    //     this.$store.getters["urls/userStorageKey"]
    //   );

    //   console.log("mysdf oas fasf as f  = = = ==  =" + loggedInUser + "   +");
    //   if (loggedInUser && this.$store.getters["user/sessionToken"] === "") {
    //     const foundUser = JSON.parse(loggedInUser);
    //     console.log("is sdf asdf ===== " + foundUser.username);
    //     this.$store.dispatch("user/setUser", foundUser);
    //     console.log("is reloaodf sf asdf ");

    //     this.$store.dispatch("user/reloadUser", {
    //       url: `${this.$store.getters["urls/baseUrl"]}/user/`,
    //     });
    //     //setUser(foundUser);
    //   }
    // },

    // create: function () {
    //   console.log("is  csofaso fasf asd f - = = = = -= -= -=  ");
    // },
};
</script>
<style scoped>
.component-divider {
    background-color: rgba(0, 0, 0, 0.12);
    position: absolute;


}

.vertical-divider {
    top: 0;
    bottom: 0;
    right: 0;
    width: 1px;
    display: none;
}

.horizontal-divider {
    left: 0;
    right: 0;
    height: 1px;
    bottom: 0;

}

.q_user_container {
    background-color: white;
    border-radius: 32px;
    border: 1px solid rgba(0, 0, 0, 0.12);
    margin-top: 16px;
}

.q-layout-row,
.q-layout-container {
    padding: 0 !important;
    margin: 0 !important;

}

.q-text-container {
    padding-right: 0px;
}

.q-credit-span {
    position: absolute;
    top: 95%;
    font-size: 0.6em;
}

.q-overlay {
    position: absolute;
    top: 0%;
    background-color: rgba(1, 1, 1, 0.1);
    width: 100%;
    height: 100%;
}

.q-profile-photo-container {
    background-color: transparent;
    padding: 0px;
    margin-bottom: 0px;
    width: 100%;
    border-radius: 0px;
    border: 0px solid rgba(0, 0, 0, 0.12);
    padding: 32px 0 32px 0;

}

.q-profile-photo-container p {
    margin: 0px
}

.q-profile-photo-img-container {
    background-color: #f8fdf8;
    border: 1px solid rgba(0, 0, 0, 0.12);
    width: 100px;
    height: 100px;
    border-radius: 50px;
}

.q-profile-photo-img-container img {
    width: 100px;
    height: 100px;
    border-radius: 50px;
    z-index: 99;
}

.q-profile-photo-img-container i {
    position: absolute;
    font-size: 2.5rem;
}

.q-photo-chooser {
    width: fit-content;
    border: 2px solid rgba(0, 0, 0.12);
    margin-top: 16px;
}

.q-photo-chooser:hover {
    background-color: rgba(0, 0, 0, 0.12);
}

#file {
    display: none;
}

.q-sub-text {
    margin-bottom: 0px;
    margin-right: 8px;
    margin-left: 8px;
}

h2 {
    font-size: 1.7em;
    margin-top: 0;
    margin-bottom: 8px;
}

div[class^="q-col"] {

    padding: 0;
}



@media (min-width: 768px) {
    .vertical-divider {
        display: block;
    }

    .horizontal-divider {
        display: none
    }
}
</style>
