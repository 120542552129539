<template>
    <div>
        <popup-dialog-element ref="popUpDialog" id="popUpDialog"></popup-dialog-element>
        <progress-overlay-element ref="q_root_overlay" id="progress_overlay"></progress-overlay-element>
        <main>
            <span class="q-credit-span">
                Image by
                <a
                    href="https://pixabay.com/users/loganwengerphotos-708161/?utm_source=link-attribution&utm_medium=referral&utm_campaign=image&utm_content=5151605">Logan
                    Wenger</a>
                from
                <a
                    href="https://pixabay.com//?utm_source=link-attribution&utm_medium=referral&utm_campaign=image&utm_content=5151605">Pixabay</a>
            </span>
            <section class=" q-has-background-img q-display-flex-column flow-center q-center q-grayed-backgroud" style="
        min-height: 100vh;
          overflow-y: auto;
          background-image: url(images/bg.jpg);
        ">
                <div class="q-overlay q-item-blur"></div>
                <div class="q-section">
                    <div class="q-layout-container q-auth-box q-progress-overlay-container"
                        style="padding: 0 !important;">
                        <progress-overlay-element id="progress_overlay"></progress-overlay-element>

                        <div class="q-layout-row">
                            <!-- <div class="q-col-sm-12 q-col-md-5 q-col-lg-5 q-col-xl-5 q-display-flex-column   flow-center "
                                style="padding: 0;">



                               

                            </div> -->

                            <div class="q-col-sm-12 q-col-md-12 q-col-lg-12 q-col-xl-12 q-display-flex-column flow-center q-center"
                                style="padding: 0;">
                                <!--Dass Logo-->
                                <a href="#">
                                    <img src="images/gw_icon.webp" class="q-auth-icon" alt="gowant writer icon" />
                                </a>
                                <form ref="form" class="q-auth-form form-container" style="max-width: 400px;"
                                    method="post" action="#">

                                    <form-input ref="username" type="text" name="text" placeHolder="用户名"
                                        label="您的用户名"></form-input>
                                    <form-input ref="password" type="password" name="password" placeHolder="密码"
                                        label="你的密码"></form-input>

                                    <Captcha ref="captcha" @verified="signIn"></Captcha>
                                    <div class="q-display-flex-column  q-form-button-container  ">



                                        <button data-can-be-disabled type="submit" name="button" id="submit"
                                            class="btn anchor-button   " style="align-self:stretch;">Login</button>

                                        <span class="q-auth-option q-display-flex-row flow-center  q-center">
                                            <span style="margin-right: 16px;"> <a href="/forgotPassword"
                                                    class="q-form-link" style="margin-bottom: 8px;">Forgot
                                                    Password?</a></span>
                                            <span style="margin-left: 16px;">
                                                <p>高文作家新手? <a ref="registerRef" data-can-be-disabled href="/register"
                                                        class="q-form-link" style="margin-bottom: 32px;">创建账户</a></p>
                                            </span></span>
                                    </div>

                                </form>

                                <legal-component></legal-component>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    </div>
</template>

<script>
import LegalComponent from "./../components/LegalComponent.vue";
import Captcha from './../components/Captcha.vue'
import { isJsonString, setCookie, getCookie, eraseCookie, showMessage, toggleDisabableElements } from "../../public/js/utils.js"
import axios from "axios";

axios.defaults.withCredentials = true;
let VERIFY_COOKIE_NAME = "verify"

// axios.interceptors.response.use((response) => response, (error) => {
//     if (typeof error.response === 'undefined') {
//         console.warn("error orccire ")
//     }
//     return Promise.reject(error)
// })


export default {
    name: "authorizationView",
    components: {
        LegalComponent, Captcha
    },
    props: {
        msg: String,
    },
    data() {
        return {
            userData: {},
            isAuthenticatingAdmin: false
        }
    },
    computed: {

    },
    watch: {

    },
    mounted: function () {

        if (this.$route.query.state == undefined || this.$route.query.scope == undefined) {
            // prevent accessing this page when not authorizing
            console.warn(" invalid request")
            this.$router.replace("/notFound")
        } else {



            // set current state
            this.$store.dispatch("status/setCurrentState", this.$route.query.state)
            this.$store.dispatch("status/setScope", this.$route.query.scope)

            var scope = this.$route.query.scope
            var item = scope.substring("scope:".length, scope.length)

            this.isAuthenticatingAdmin = (item == "console")

            if (this.$route.query.mode === "1") {
                //register user

                this.redirectToRegister();
            }


        }
        this.$refs.registerRef.addEventListener('click', e => {
            e.preventDefault()
            this.redirectToRegister()
        })

        this.$refs.form.addEventListener("submit", (e) => {
            e.preventDefault()
            const userPasswordElement = this.$refs.password

            const userEmailElement = this.$refs.username
            if (!userEmailElement.isValid) {
                showMessage("请检查您的用户名。", true);
            } else if (!userPasswordElement.isValid) {
                showMessage("请检查您的密码", true);
            } else {
                this.userData = {
                    username: userEmailElement.inputValue,
                    password: userPasswordElement.inputValue
                };
                if (this.$refs.captcha.visible()) {
                    this.$refs.captcha.verify()
                } else {
                    this.signIn();
                }
            }


        });

        this.$refs.captcha.show(getCookie(VERIFY_COOKIE_NAME) == "true")


    },
    methods: {
        redirectToRegister: function () {

            this.$router.push({ path: "/register", query: { "state": this.$route.query.state, "scope": this.$route.query.scope } });
        },
        signIn: async function () {

            let UserForm = new FormData();
            UserForm.append('username', this.userData.username);
            UserForm.append('password', this.userData.password);

            toggleDisabableElements(true);
            try {
                var result = await axios.post(
                    `${this.$store.getters["urls/baseUrl"]}/oauth/login?state=${this.$route.query.state}`,
                    UserForm
                );

                var data = result.data;

                toggleDisabableElements(false);
                if (data.error !== undefined && data.error !== null) {
                    // failed to login
                    showMessage(data.error, true);
                } else {

                    //remove verfity cookie
                    eraseCookie(VERIFY_COOKIE_NAME);



                    this.$refs.captcha.show(false)
                    this.$router.push("/verify");



                }
            } catch (e) {
                this.$refs.captcha.show(true)
                toggleDisabableElements(false);

                if (e.response && e.response.data && e.response.data.detail) {

                    if (isJsonString(e.response.data.detail)) {
                        var error = JSON.parse(e.response.data.detail)

                        if (error.code && error.code != 0) {
                            showMessage(error.message, true);
                            // if (error.error == "username or password incorrect.") {

                            // expires in 7 days
                            setCookie(VERIFY_COOKIE_NAME, "true", 7);


                            //}
                            return
                        }
                    } else {
                        setCookie(VERIFY_COOKIE_NAME, "true", 7);


                        showMessage(e.response.data.detail, true)
                    }
                }

                //showMessage("Login failed, please try again", true);


            }
        },


        redirectUser: function () {

            this.showLoadingState(this.isLoadingUser);


        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.q-layout-row {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.q-text-container {
    padding-right: 32px;
}

.q-credit-span {
    position: absolute;
    top: 95%;
    font-size: 0.6em;
}

.q-overlay {
    position: absolute;
    top: 0%;
    background-color: transparent;
    width: 100%;
    height: 100%;
}
</style>
