<template>
    <div class=" q-display-flex-column  q-center q-flex-center-items" style="width: 100%;">
        <div class="main-content-container q-display-flex-column  q-center q-flex-center-items">
            <h2 class="q-align-self-left" style="margin-top: 0; margin-bottom: 32px;">Presets</h2>
            <ContentHeaderComponent :controls="controls" searchPlaceHolder="Find a preset by title"
                @searchItem="handleSearch" @controlData="handleControlClick">
            </ContentHeaderComponent>

            <div :class="showPlaceHolderELement"
                class=" q-place-holder q-display-flex-column  q-center q-flex-center-items">

                <i class="ri-emotion-normal-line"></i>
                <p>Oops, No presets yet, click "Add" to create a one</p>
            </div>
            <table :class="showTableELement" style="visibility: visible;">
                <tr style="background-color:transparent;border-radius: 8px;">
                    <th v-for="(header) in headers" :key="header.key">
                        {{ header.name }}
                    </th>
                    <th style="width: 32px;">

                    </th>
                </tr>
                <tr data-can-be-disabled :class="currentPresetId == row.objectId ? 'current-row' : ''"
                    class="q-animatable" v-for="(row) in tableData" :key="row.objectId">
                    <td @click="viewUserDetails($event, row)">
                        {{ row.objectId }}
                    </td>
                    <td @click="viewUserDetails($event, row)">
                        {{ row.name }}
                    </td>
                    <td @click="viewUserDetails($event, row)">
                        {{ formatData(row.sections) }}
                    </td>
                    <td @click="viewUserDetails($event, row)">
                        {{ row.type }}
                    </td>
                    <td @click="viewUserDetails($event, row)">
                        {{ row.createdAt }}
                    </td>
                    <td @click="viewUserDetails($event, row)">
                        {{ row.updatedAt }}
                    </td>
                    <OptionsPopup @setCurrent="setCurrent" @controlData="handleRowControlClick" :controls="rowControls"
                        :rowData="row">
                    </OptionsPopup>

                </tr>
            </table>
            <ContentTableFooter ref="footerItem" :totalCount="totalPresets" @next="nextPage" @prev="prevPage"
                @limitChanged="loadPresets" @queryReady="loadPresets">
            </ContentTableFooter>
        </div>
        <PreviewPresetDrawer @edit="editPreset" @deleted="loadPresets" ref="editDialog"></PreviewPresetDrawer>
        <EditPresetDialog ref="mainEditDialog" @edited="loadPresets" @deleted="loadPresets">
        </EditPresetDialog>
        <CreatePresetDrawer @created="loadPresets" ref="detailsDialog">
        </CreatePresetDrawer>
    </div>
</template>

<script>

import { showLoadingState, showMessage, joinArrayToStr } from "../../../public/js/utils.js"
import ContentHeaderComponent from "../../components/admin/ContentHeaderComponent.vue"
import CreatePresetDrawer from "../../components/admin/CreatePresetDrawer.vue"
import PreviewPresetDrawer from "../../components/admin/PreviewPresetDrawer.vue"
import EditPresetDialog from "../../components/admin/EditPresetDialog.vue"
import OptionsPopup from "../../components/admin/OptionsPopup.vue"
import ContentTableFooter from "../../components/admin/ContentTableFooter.vue"

import axios from "axios";

axios.defaults.withCredentials = true;
export default {
    name: "users-compoent",
    components: {
        CreatePresetDrawer,
        ContentHeaderComponent,
        PreviewPresetDrawer,
        OptionsPopup,
        EditPresetDialog, ContentTableFooter
    },
    data() {
        return {
            currentPresetId: "",
            hasData: true,
            totalPresets: 0,
            rowControls: [

                {
                    "key": 0,
                    "name": "Edit",
                    "toolTip": "Edit Preset",

                },
                {
                    "key": 1,
                    "name": "Delete",
                    "toolTip": "Delete Preset",

                },
            ],
            controls: [
                {
                    "key": 0,
                    "name": "Refresh",
                    "toolTip": "Refresh Presets",
                    "icon": "ri-refresh-line"
                },
                {
                    "key": 1,
                    "name": "Add",
                    "toolTip": "Add Preset",
                    "icon": "ri-add-circle-line"
                },
                {
                    "key": 2,
                    "name": "Edit",
                    "toolTip": "Edit Preset",
                    "icon": "ri-pencil-line"
                },
                {
                    "key": 3,
                    "name": "Delete",
                    "toolTip": "Delete Preset",
                    "icon": "ri-delete-bin-line"
                },
            ], headers: [
                { "name": "ObjectId", "key": "" },
                { "name": "Title", "key": "" },
                { "name": "Scope", "key": "" },
                { "name": "Type", "key": "" },
                { "name": "Created At", "key": "" },
                { "name": "Updated At", "key": "" },
            ],
            tableData: [

            ]
        }
    },
    props: {
        users: [],
    },
    computed: {
        isLoadingUser() {
            return this.$store.getters["admin/isReloadingLoadingUser"];
        },
        isLoggedIn() {
            return this.$store.getters["admin/isLoggedIn"];
        },
        showPlaceHolderELement() {
            return !this.hasData ? "showPlaceHolder" : ""
        },
        showTableELement() {
            return this.hasData ? "" : "hideTable"
        },
        currentPresetData() {
            return this.$store.getters["current/currentPreset"];
        },
        isCurrentPreset() {
            var data = this.$store.getters["current/currentPreset"]
            console.log("mi == " + "  ")

            if (data.objectId != undefined && this.currentPresetId == data.objectId)
                return "current-row"
            return ""
        }

    },
    watch: {
        isLoadingUser(new_, old_) {
            if (new_ != old_) {
                this.loadPresets();
            }
        },
        currentPresetData(new_, old_) {

            if (new_ != old_) {
                this.setCurrentPresetId();
            }
        },

    },
    methods: {
        nextPage: function () { },
        prevPage: function () { },

        setCurrentPresetId: function () {
            this.currentPresetId = this.currentPresetData && this.currentPresetData.objectId ? this.currentPresetData.objectId : ""

        },
        formatData: function (data) {
            return joinArrayToStr(data)
        },
        handleSearch: function (event, data) {

            console.trace()
            if (!data || data === "") {
                this.loadPresets()
            } else {
                this.loadPresets({
                    query: `where={"name":"${data}"}`
                })
            }
        },
        loadPresets: async function (data) {
            console.log("my dattasdasdf asf " + data + "  " + event)
            // reset current preset
            this.$store.dispatch("current/setCurrentPreset", {})
            // let limit = 10
            // if (data)
            //     limit = data

            if (!this.isLoggedIn)
                return


            let url = `${this.$store.getters["urls/adminBaseUrl"]}/presets/all/limit=${499}`

            if (data) {
                if (data.limit)
                    url = `${this.$store.getters["urls/adminBaseUrl"]}/presets/all/limit=${data.limit}`
                else if (data.query)
                    url = `${this.$store.getters["urls/adminBaseUrl"]}/presets/all/${data.query}`
            }
            console.log("my sdf sdf asdf asf " + url)
            try {
                showLoadingState(true)
                var result = await axios.get(
                    url,
                );
                showLoadingState(false)


                this.tableData = result.data.results;
                this.hasData = this.tableData.length > 0
                if (data && data.isNext != undefined) {
                    if (data.isNext) {
                        this.$refs.footerItem.increamentNextPage()
                    } else {
                        this.$refs.footerItem.decreamentNextPage()
                    }
                }

            } catch (error) {
                showLoadingState(false)
                showMessage("Failed to load presets", true)
            }
            this.countPresets()
        },
        countPresets: async function () {

            if (!this.isLoggedIn)
                return

            try {
                var result = await axios.get(
                    `${this.$store.getters["urls/adminBaseUrl"]}/presets/all/limit=0&count=1`,
                );
                this.totalPresets = result.data.count;

            } catch (error) {
                console.warn("Failed to count presets , retrying " + error)
                setTimeout(() => {
                    this.countPresets()
                }, 10000);
            }
        },
        viewUserDetails: function (event, data) {

            this.$store.dispatch("current/setCurrentPreset", data)
            this.$refs.editDialog.show()
        },
        setCurrent: function (event, row) {
            var data_ = this.tableData.filter(a => {
                return a.objectId === row.objectId
            })

            this.$store.dispatch("current/setCurrentPreset", data_.length > 0 ? data_[0] : row)
        },
        handleRowControlClick: function (event, data) {
            console.log(" mysdfu asdfasdf " + JSON.stringify(data))
            if (this.currentPresetData.objectId) {
                if (data.key === 0) {
                    this.editPreset()
                } else if (data.key === 1) {
                    this.$refs.editDialog.startDeleteUser()
                }
            } else {
                showMessage("Please select a preset ", false)
            }
        },
        handleControlClick: function (event, data) {
            console.log(" mysdfu asdfasdf " + JSON.stringify(data))
            if (data.key === 1) {
                this.createPreset()
            } else if (data.key === 0) {
                this.loadPresets()
            } else if (data.key === 2) {
                if (this.currentPresetData.objectId) {
                    this.editPreset()
                } else {
                    showMessage("Please select a preset ", false)
                }
            } else if (data.key === 3) {

                if (this.currentPresetData.objectId) {
                    this.$refs.editDialog.startDeleteUser()
                } else {
                    showMessage("Please select a preset ", false)
                }
            }
        },
        editPreset: function () {
            this.$refs.mainEditDialog.show()
        },
        createPreset: function () {
            this.$refs.detailsDialog.show()
        }
    },
    mounted: function () {

        this.loadPresets();

    },

};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.main-content-container {
    position: relative;
}



.q-place-holder {
    max-width: 1000px;
    background-color: transparent;
    width: 500px;
    height: 500px;
    display: none;
}

.q-place-holder p {
    font-size: 1em;
    opacity: .5;
    text-align: center;
    max-width: 250px;
}

.q-place-holder i {
    font-size: 7em;
    opacity: .3;
}

.hideTable {
    visibility: hidden !important;
}

.showPlaceHolder {
    display: flex !important;
}
</style>
