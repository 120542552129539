export default {
  namespaced: true,
  state: {
    currentPresetData: {},
    currentUserData: {},
    currentAiModeData: {},
    currentAiTypeData: {}
  },
  mutations: {
    UPDATE_CURRENT_PRESET(state, payload) {
      state.currentPresetData = payload;
    },
    UPDATE_CURRENT_USER(state, payload) {
      state.currentUserData = payload;
    },
    UPDATE_CURRENT_AI_MODEL(state, payload) {
      state.currentAiModeData = payload;
    },
    UPDATE_CURRENT_AI(state, payload) {
      console.log("setting current ai " + JSON.stringify(payload));

      state.currentAiTypeData = payload;
    }
  },
  actions: {
    setCurrentPreset(context, payload) {
      context.commit("UPDATE_CURRENT_PRESET", payload);
    },
    setCurrentAi(context, payload) {
      context.commit("UPDATE_CURRENT_AI", payload);
    },
    setCurrentUser(context, payload) {
      context.commit("UPDATE_CURRENT_USER", payload);
    },
    setCurrentAiModel(context, payload) {
      context.commit("UPDATE_CURRENT_AI_MODEL", payload);
    }
  },
  getters: {
    currentPreset: function (state) {
      return state.currentPresetData;
    },
    currentUser: function (state) {
      return state.currentUserData;
    },
    currentAiModel: function (state) {
      return state.currentAiModeData;
    },
    currentAi: function (state) {
      return state.currentAiTypeData;
    }
  }
};
