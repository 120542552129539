<template>
    <div class="root-element q-display-flex-row  ">
        <div :class="showAuthButtons" style="display: none;"
            class=" content-container auth-buttons-container q-display-flex-row q-flex-center-items q-center ">
            <a href="" class="q-display-flex-row q-flex-center-items q-center"><span>Login</span></a>
            <a href="" class="q-display-flex-row q-flex-center-items q-center"><span>Register</span></a>
        </div>
    

        <div class=" content-container  user-name-container q-display-flex-row q-flex-center-items q-center">
            <h4>{{ userName }}</h4>
        </div>

        <div class=" content-container  drop-icon-container q-display-flex-row q-flex-center-items q-center">
            <button ref="popToggler" @click="showPopUp"><i class="ri-arrow-down-s-line"></i></button>
        </div>

        <div ref="popupItem" style="display: none;" class="user-details-popup elevate-5 q-display-flex-column ">

            <div class=" q-layout-container q-progress-overlay-container ">
                <div class="q-layout-row  child-content-container ">
                    <div class=" q-col-7 q-display-flex-column  q-center">
                        <div class="popup-user-details-container q-display-flex-column">
                            <h4>{{ userName }}</h4>

                            <p style="opacity: .7;">{{ userPoints }} points</p>
                        </div>
                    </div>
                    <div class=" q-col-5 q-display-flex-column  q-flex-center-items q-center">
                        <div class=" img-container-pop q-display-flex-row q-flex-center-items q-center">
                            <div class="pop-close-container q-display-flex-column  q-flex-center-items q-center">
                                <button @click="close" title="Close"><i class="ri-close-large-line"></i></button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div class="q-divider"></div>

            <div class="pop-link-container">
                <ul>
                    <li>
                        <a href="#">Recharge points</a>
                    </li>
                    <li>
                        <a href="/profile">Account Settings</a>
                    </li>
                    <li>
                        <a href="/signout">Sign Out</a>
                    </li>

                </ul>
            </div>
            <div class="q-divider"></div>
            <div class="legal-container q-display-flex-row q-flex-center-items q-center">
                <button><a href="">Privacy Policy</a></button>
                <button><a href="">Terms of Use</a></button>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
axios.defaults.withCredentials = true;
export default {

    name: "UserAvatarContainer",

    mounted() {
        // auto close popup
        this.emitter.on("av-click", event => {
            console.log("is clicked ")
            if ((this.$refs.popToggler !== null && !this.$refs.popToggler.contains(event.target)) || (this.$refs.popupItem != null && this.$refs.popupItem.contains(event.target))) {
                this.close()
            }
        });

        window.onclick = (event) => {
            console.log("wind ei dsfo asdf asf ")
            this.emitter.emit("av-click", event);
        };
        this.redirectUser()
    },
    // updated() {
    //     console.log("is upatebdsdf as f")
    // },
    // beforeUpdate() {
    //     console.log("before updated ")
    // },
    // beforeUnmount() {
    //     alert("beforeUnmount: The text inside the p-tag is: ");
    // },
    // activated() {
    //     console.log("activated");
    // },
    // deactivated() {
    //     console.log("deactivated");
    // },
    computed: {
        userPoints() {
            return this.$store.getters["user/points"];
        },
        isLoadingUser() {
            return this.$store.getters["user/isReloadingLoadingUser"];
        },
        showAdminButton() {
            return this.isAdmin ? "display:flex" : "display:none"
        },
        isLoggedIn() {
            return (
                this.$store.getters["user/isLoggedIn"] &&
                !this.$store.getters["user/isReloadingLoadingUser"]
            );
        },
        userName() {
            return this.$store.getters["user/username"]
        },
        userInitials() {
            return this.userName != "" ? this.userName.at(0) : ""
        },
        photoUrl() {
            return this.$store.getters["user/photoUrl"]
        },
        showInitials() {
            return this.photoUrl != "" ? "hideInitials" : ""
        },
        showAuthButtons() {
            return !this.isLoggedIn ? "show" : ""
        }
    },
    data() {
        return {
            isAdmin: false
        }
    },
    watch: {
        isLoadingUser(new_, old_) {

            if (new_ != old_) {
                this.redirectUser();
            }
        },
        isLoggedIn(new_, old_) {

            if (new_ != old_) {
                this.redirectUser();
            }
        },
    },
    methods: {
        close: function () {
            this.$refs.popupItem.classList.remove("show")
        },
        fetchUserRole: async function () {
            console.log(" = = = = Mysd fasdf asf = = = =  " + JSON.stringify(this.$store.getters["user/user"]))
            if (this.$store.getters["user/user"].objectId == undefined)
                return
            try {

                var result = await axios.get(
                    `${this.$store.getters["urls/adminBaseUrl"]}/users/role/${this.$store.getters["user/user"].objectId}`,

                );

                var data = result.data;

                this.isAdmin = (data && data.role && data.role === "admin")


            } catch (e) {
                // if (e.response && e.response.data && e.response.data.detail) {
                //     var error = JSON.parse(e.response.data.detail)
                //     if (error.error) {
                //         this.showMessage(error.error, true);
                //         return
                //     }
                // }

                // this.showMessage("Login failed, please try again", true);


            }
        },
        redirectUser: function () {
            console.log("is redirecting user ")
            this.fetchUserRole()
        },
        showPopUp: function () {
            this.$refs.popupItem.classList.add("show")
        }
    }

};
</script>


<style scoped>
button {
    line-height: normal !important;
    cursor: pointer;
}

.pop-close-container {
    background-color: transparent;
    position: absolute;
    z-index: 9999;
    right: 8px;
    top: 8px;
    width: 32px;
    height: 32px;
    border-radius: 50% 50%;
}


.show {
    display: flex !important;
}

.root-element {
    position: relative;
}

.content-container {
    height: 56px;
}

.auth-buttons-container a {
    margin-left: 8px;
    margin-right: 8px;
    background-color: var(--gw_primary_color);
    padding-left: 16px;
    padding-right: 16px;
    height: 36px;
    color: white;
    font-family: main_font_bold;
    font-size: .9em;
    border-radius: 32px;
}

.auth-buttons-container a span {
    margin-bottom: 1px
}



.user-name-container h4 {
    margin: 0px;
    font-family: main_font_bold;
}

button {
    background-color: transparent;
}

.img-container {
    width: 56px;
}

.img-container-pop {
    width: 100px;
    height: 42px;
}

.img-container-pop span {
    width: 64px !important;
    height: 64px !important;
}

.img-container span,
.img-container-pop span {
    position: relative;
    background-color: #41d0b6;
    width: 32px;
    height: 32px;
    border-radius: 50% 50%;
    overflow: hidden;
}

.hideInitials {
    display: none;
}

.img-container span img,
.img-container-pop span img {
    width: inherit;
    height: inherit;
    object-fit: cover;
}

.user-place-holder-container-pop {
    width: 64px;
    height: 64px !important;
}

.user-place-holder-container,
.user-place-holder-container-pop {
    position: absolute;
    width: 42px;
    height: 42px !important;

}

.user-place-holder-container-pop p {
    font-size: 1.6em !important;
}

.user-place-holder-container p,
.user-place-holder-container-pop p {
    margin: 0;
    font-family: main_font_bold;

}

.drop-icon-container button i {
    font-size: 1.5em;
    cursor: pointer;
}

.user-details-popup {
    width: 300px;

    background-color: white;
    position: absolute;
    right: 5%;
    top: 100%;
    border-radius: 24px;
}

.user-details-popup .q-layout-container,
.user-details-popup .q-layout-row,
.user-details-popup .q-col-7,
.user-details-popup .q-col-5 {
    padding: 0;
}

.popup-user-details-container {
    padding-left: 16px;
    padding-top: 16px;
    padding-bottom: 16px;
}

.popup-user-details-container h4 {
    margin-top: 0px;
    font-family: main_font_bold;
    font-size: 1.2em;
    margin-bottom: 4px;
}

.popup-user-details-container p {

    margin-bottom: 4px;
}

.pop-link-container ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    padding-top: 16px;
    padding-bottom: 16px;
}

.pop-link-container ul li {

    margin: 0;
    padding-left: 16px;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-right: 16px;
}

.pop-link-container ul li:hover,
.pop-close-container :hover {
    background-color: var(--nav-selected);
}

.pop-link-container ul li a {
    color: var(--gw_text_color);

}

.legal-container {
    height: 42px;
    padding-left: 32px;
    padding-right: 32px;
}

.legal-container button {
    width: 100%;
}

.legal-container button a {

    color: var(--gw_text_color);
    opacity: .6;
    font-family: main_font_bold;
    font-size: .8em;
}
</style>