<template>
    <dialog aria-modal="true" aria-label="user Details"
        class=" q-animatable q-delay-fast elevate-5 details-container  q-display-flex-column " ref="detailsDialog">
        <progress-overlay-element ref="q_overlay"></progress-overlay-element>

        <div class="drawer-header  q-display-flex-row q-align-stretch">
            <h3 style="margin: 0px;width: 100%;">Edit Model</h3>
            <button data-can-be-disabled @click="close" class=" q-display-flex-column q-center q-flex-center-items"
                title="close">
                <i class="ri-close-circle-line q-align-self-right"></i>
            </button>

        </div>
        <div class="q-divider"></div>

        <div class="drawer-content-container">

            <div class=" q-layout-container q-progress-overlay-container content-container">

                <div class="q-layout-row  child-content-container">
                    <form action="#" ref="form" method="post" style="width: 100%;">

                        <form-input isReadonly="true" ref="aiElement" type="text" name="text"
                            placeHolder="Ai Model Name" label="Ai Type"></form-input>

                        <form-input ref="modelElement" type="text" name="text" placeHolder="Ai Model Name"
                            label="AI Model Name"></form-input>
                        <form-input ref="modelNameElement" type="text" name="text" placeHolder="Name"
                            label="Model Name"></form-input>

                        <form-input ref="cost" type="text" name="int" placeHolder="Cost"
                            label="Cost Per 1000 tokens"></form-input>



                        <div data-can-be-disabled class="q-custom-dropdown">

                            <input data-can-be-disabled ref="urlInput" type="url" name="text" placeHolder="Url" />
                            <select ref="urlSelectRef">
                                <option v-for="(item) in defaultUrls" :key="item.key">{{ item.url }}</option>

                            </select>
                        </div>

                        <div style="margin-top: 16px;margin-bottom: 16px;" class="q-display-flex-row">
                            <input ref="aiModelState" id="modelState" type="checkbox" checked>
                            <label for="modelState" style="margin-left: 8px">Ai model enabled</label>
                        </div>
                        <div class=" q-form-button-container q-display-flex-column  ">
                            <button ref="editButton" data-can-be-disabled type="submit" name="button"
                                class="btn anchor-button   " style="align-self:stretch;">Edit</button>
                        </div>
                    </form>
                </div>



            </div>
        </div>
    </dialog>
</template>

<script>
import { showMessage, toggleDisabableElements } from "../../../public/js/utils.js"

import axios from "axios";
axios.defaults.withCredentials = true;
export default {
    name: "EditPresetDialog",
    props: ["defaultUrls"],
    components: {

    },
    data() {
        return {
            supportedPages: [
            ],
            currentAiData: {}
        }
    },
    mounted() {
        this.$refs.urlSelectRef.addEventListener("change", () => {
            console.log("os af asdf as;f ;asf;a sd;f ")
            this.$refs.urlInput.value = this.$refs.urlSelectRef.value;
            this.$refs.urlInput.focus()
        })
        this.$refs.form.addEventListener("submit", e => {
            e.preventDefault()

            this.editPreset()
        })
    },
    methods: {
        resetInputs: function () {
            this.$refs.modelElement.inputElement.value = ""
            this.$refs.modelNameElement.inputElement.value = ""
            this.$refs.cost.inputElement.value = ""
            this.$refs.urlInput.value = ""
        },
        show: async function () {
            var data = this.$store.getters["current/currentAiModel"]
            this.$refs.detailsDialog.showModal()
            this.loadModel(data)
            this.resetInputs()
        },
        close: function () {
            this.$refs.detailsDialog.close()
        },
        editPreset: async function () {
            try {
                this.showOverlay(true)
                toggleDisabableElements(true)
                var currentAiModel = this.$store.getters["current/currentAiModel"]
                let data = this.getUpdatedData()
                console.log("mt adf asfasf " + JSON.stringify(data))
                var result = await axios.post(`${this.$store.getters["urls/adminBaseUrl"]}/ai/models/edit/${currentAiModel.objectId}`, data)

                console.log("my reslt sfa sfas f " + JSON.stringify(result))
                if (result.data.updatedAt != "") {
                    this.close()
                    this.$emit('edited', '')
                } else {

                    showMessage("Failed to edit preset", true)
                }
                toggleDisabableElements(false)
                this.showOverlay(false)

            } catch (error) {
                console.log("my errpr " + error)
                this.showOverlay(false)
                toggleDisabableElements(false)
                showMessage("Failed to edit preset", true)
            }

            console.log("delete user ")

        },
        showOverlay: function (show) {
            this.$refs.q_overlay.run = show
            toggleDisabableElements(show)

        },
        startDeleteUser: async function () {
            this.$refs.dialog_component.show(undefined, { "title": "Delete", "description": "Are you sure you want to delete this preset?" })
        },
        deletePreset: async function () {
            try {
                var data = this.$store.getters["current/currentAiModel"]
                if (!data || !data.objectId) {
                    showMessage("Failed to delete model", true)
                    return
                }

                if (!this.$refs.detailsDialog.classList.contains("showDrawer")) {
                    this.show()
                }
                this.showOverlay(true)
                toggleDisabableElements(true)
                console.log("is defsdfl asdl fsdf ")
                var result = await axios.post(`${this.$store.getters["urls/adminBaseUrl"]}/ai/models/delete/${data.objectId}`)
                console.log("my reslt sfa sfas f " + JSON.stringify(result))
                if (result.data.msg == "ok") {
                    this.close()
                    this.$emit('deleted', '')
                } else {

                    showMessage("Failed to delete model", true)
                }
                toggleDisabableElements(false)
                this.showOverlay(false)

            } catch (error) {
                console.log("my errot is sdf asdf " + error)
                this.showOverlay(false)
                toggleDisabableElements(false)
                showMessage("Failed to delete model", true)
            }
        },
        setElementPlaceHolder: function (el, value) {
            if (el) {
                el.setAttribute("placeHolder", value)
                el.render();
                el.inputElement.value = value
            }
        },
        getElementValue: function (el, key) {
            if (el && el.inputValue !== "") {

                return el.inputValue
            } else {
                var data = this.$store.getters["current/currentAiModel"]
                return data[key]
            }
        },
        getUpdatedData: function () {
            //  var data = this.$store.getters["current/currentAiModel"]
            var mData = {}
            mData.aiType = this.currentAiData.objectId
            mData.modelName = this.getElementValue(this.$refs.modelElement, "modelName")
            mData.pointCost = this.getElementValue(this.$refs.cost, "pointCost")
            mData.name = this.getElementValue(this.$refs.modelNameElement, "name")
            //  mData.url = this.getElementValue(this.$refs.urlInput, "url")
            mData.enabled = this.$refs.aiModelState.checked
            var el = this.$refs.urlInput
            if (el && el.value !== "") {
                mData.url = el.value
            } else {
                var data = this.$store.getters["current/currentAiModel"]
                mData.url = data.url
            }
            return mData;

        },
        loadModel: async function () {

            let resultData = this.$store.getters["current/currentAiModel"]

            if (resultData) {
                try {
                    console.log("i sdfo sdf asdf " + resultData.aiType.objectId)
                    this.showOverlay(true)
                    var _data = await axios.get(`${this.$store.getters["urls/baseUrl"]}/ai/ai/${resultData.aiType.objectId}`)
                    console.log("my faos fasdf a " + JSON.stringify(_data) + " leng  ")
                    this.showOverlay(false)
                    if (!_data.data) {

                        this.close()
                        showMessage("Failed to load model data, please try again")
                        return
                    }
                    this.currentAiData = _data.data

                } catch (e) {
                    this.showOverlay(false)
                    console.log("an eroegs sdfg sfg " + e)
                }
                console.log("msduf iasd fasdf sf " + JSON.stringify(this.currentAiData))
                this.setElementPlaceHolder(this.$refs.aiElement, this.currentAiData.name)
                this.setElementPlaceHolder(this.$refs.modelElement, resultData.modelName)
                this.setElementPlaceHolder(this.$refs.cost, resultData.pointCost)
                this.setElementPlaceHolder(this.$refs.modelNameElement, resultData.name)
                // this.setElementPlaceHolder(this.$refs.urlInput, resultData.url)
                this.$refs.urlInput.value = resultData.url
                console.log("mt s fasdf asf  = = = = " + JSON.stringify(resultData))
                this.$refs.aiModelState.checked = resultData.enabled != undefined ? resultData.enabled : true
                console.log("is ldf aslf as " + this.$refs.aiModelState.checked + "   " + resultData.enabled)
                this.presetDetails = []
                this.showOverlay(false)
            }


        }
    }
};
</script>
<style scoped>
dialog {
    position: absolute;
}

.showDrawer {
    width: 400px !important;
    opacity: 1 !important;
}

.details-container {

    background-color: white;

    width: 600px;
    right: 0;
    top: 0;
    padding: 0px;

}

.drawer-header button:hover {
    background-color: var(--nav-current)
}

.drawer-header button {

    border-radius: 32px;
    line-height: 1;
    height: 32px;
    width: 10%;
}

.drawer-header button i {
    font-size: 1.4em;
}

.drawer-header {
    height: 32px;
    padding: 16px;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 20px;
}

.q-divider {
    margin-top: 16px;
    margin-bottom: 0px;
}

.drawer-content-container {
    height: 100%;
    width: 100%;
    overflow: auto;
}



.child-content-container p,
.child-content-container h4 {
    margin: 0;
}

.child-content-container h4 {
    font-family: main_font_bold;
    font-size: .9em;
}

.group-title-container {
    background-color: var(--theme-color-navbar-bg);

}

.q-layout-row {
    width: 100%;
}

.q-button {
    width: 100%;
    background-color: var(--q_red);
    color: white !important;
}

.q-button p {
    color: white;
    margin-left: 8px
}
</style>
