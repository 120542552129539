export default {
  namespaced: true,
  state: {
    stateValue: "",
    scope: "",
    caller: -1
  },
  mutations: {
    UPDATE_STATE(state, payload) {
      state.stateValue = payload;
    },
    UPDATE_CALLER(state, payload) {
      state.caller = payload;
    },
    UPDATE_SCOPE(state, payload) {
      state.scope = payload;
    }
  },
  actions: {
    setCurrentState(context, payload) {
      context.commit("UPDATE_STATE", payload);
    },
    setScope(context, payload) {
      context.commit("UPDATE_SCOPE", payload);
    },
    setCaller(context, payload) {
      context.commit("UPDATE_CALLER", payload);
    }
  },
  getters: {
    stateValue: function (state) {
      return state.stateValue;
    },
    scope: function (state) {
      return state.scope;
    },
    caller: function (state) {
      return state.caller;
    }
  }
};
