<template>
    <td ref="td" @click="show"
        style="width: 32px; z-index: 999;padding-left: 0 !important;padding-right: 0 !important;">
        <i class="ri-more-2-fill"></i>
        <div style="display: none;" id="pop" ref="popupDialog" class="elevate-5 root-element q-display-flex-column ">
            <button @click="handleControlClick($event, item)" v-for="(item) in controls" :key="item.key"
                :title="item.toolTip"><span>{{ item.name }}</span></button>


        </div>
    </td>
</template>

<script>
export default {
    name: "OptionsPopup",
    props: [
        "controls", "rowData",
    ],
    data() {
        return {
            rowItemData: this.rowData
        }
    },

    mounted() {

        // auto close popup
        this.emitter.on("click", event => {

            if (this.$refs.td !== null && !this.$refs.td.contains(event.target) && this.$refs.popupDialog != null) {
                this.close()
            }
        });

        window.onclick = (event) => {
            this.emitter.emit("click", event);
        };
    },
    methods: {
        show: function () {
            console.log("mt erofas asf as " + JSON.stringify(this.rowItemData))

            // this.$store.dispatch("current/setCurrentPreset", this.rowItemData)
            this.$emit('setCurrent', event, this.rowItemData)
            this.$refs.popupDialog.classList.add("show")
        },
        handleControlClick: function (event, data) {

            this.$emit('controlData', event, data)
            this.close()

        }, close: function () {

            this.$refs.popupDialog.classList.remove("show")
        }
    }
};
</script>

<style scoped>
.root-element {
    width: 150px;
    background-color: white;
    position: absolute;
    right: 100%;
    border-radius: 16px;
    padding-top: 8px;
    padding-bottom: 8px
}

.root-element button {

    background-color: transparent;
    border-radius: 4px;
    padding-top: 8px;
    padding-bottom: 8px;
    text-align: left
}

.root-element button span {
    padding-left: 12px;
}

.root-element button:hover {
    background-color: var(--nav-selected);
    cursor: pointer;
}

.show {
    display: flex !important;
}

.content-container {
    padding: 0 !important;
}

td {
    position: relative;
}
</style>
