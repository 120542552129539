<template>
    <div aria-modal="true" aria-label="user Details"
        class=" q-animatable elevate-5 details-container  q-display-flex-column " ref="detailsDialog">
        <progress-overlay-element ref="q_overlay"></progress-overlay-element>
        <DialogComponent @accepted="deletePreset" ref="dialog_component"></DialogComponent>

        <div class="drawer-header  q-display-flex-row q-align-stretch">
            <h3 style="margin: 0px;width: 100%;">Preview Preset</h3>
            <button data-can-be-disabled @click="close" class=" q-display-flex-column q-center q-flex-center-items"
                title="close">
                <i class="ri-close-circle-line q-align-self-right"></i>
            </button>

        </div>
        <div class="q-divider"></div>

        <div class="drawer-content-container">

            <div class=" q-layout-container q-progress-overlay-container content-container">

                <div class="q-layout-row  q-animatable  child-content-container  group-title-container">
                    <div
                        class="content-container q-col-sm-12 q-col-md-6 q-col-lg-6 q-col-xl-6 q-display-flex-column q-text-container">
                        <p>Details</p>
                    </div>
                </div>
                <div v-for="(detail) in presetDetails" :key="detail.key" class="q-layout-row  child-content-container">
                    <div
                        class=" content-container q-col-sm-12 q-col-md-6 q-col-lg-6 q-col-xl-6 q-display-flex-column q-text-container">
                        <h4 style="margin: 0;">{{ detail.title }}<span>:</span></h4>
                    </div>
                    <div
                        class=" content-container q-col-sm-12 q-col-md-6 q-col-lg-6 q-col-xl-6 q-display-flex-column q-text-container">
                        <p style="margin: 0;">{{ detail.value }}</p>
                    </div>
                </div>
                <!--Controls-->
                <div class="q-layout-row   child-content-container  group-title-container">
                    <div class="content-container q-col-12  q-display-flex-column q-text-container">
                        <p>Delete</p>
                    </div>
                </div>
                <div class="q-layout-row  child-content-container button-container ">
                    <div class="content-container q-col-6 q-display-flex-column q-text-container">
                        <button data-can-be-disabled @click="edit"
                            class="q-button q-display-flex-row q-center q-flex-center-items "
                            style="background-color: var(--gw_primary_color);">
                            <i class="ri-pencil-line"></i>
                            <p>Edit</p>
                        </button>
                    </div>
                    <div class="content-container q-col-6 q-display-flex-column q-text-container">
                        <button data-can-be-disabled @click="startDeleteUser"
                            class="delete-button q-button q-display-flex-row q-center q-flex-center-items "><i
                                class="ri-delete-bin-6-line"></i>
                            <p>Delete </p>
                        </button>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import { showMessage, toggleDisabableElements, joinArrayToStr } from "../../../public/js/utils.js"
import DialogComponent from "./DialogComponent.vue"
import axios from "axios";
axios.defaults.withCredentials = true;
export default {
    name: "PreviewPresetDrawer",
    components: {
        DialogComponent
    },
    computed: {
        presetName() {
            var data = this.$store.getters["current/currentPreset"]


            if (data.name != undefined)
                return data.name
            return ""
        }
    },
    data() {
        return {
            presetDetails: [],
        }
    },
    mounted() {

    },
    methods: {
        show: async function () {
            var data = this.$store.getters["current/currentPreset"]
            this.$refs.detailsDialog.classList.add("showDrawer")
            console.log("loai fpwpfsdfl asd f")
            this.presetDetails = []
            this.loadPreset(data)

        },
        close: function () {
            this.$refs.detailsDialog.classList.remove("showDrawer")
        },

        showOverlay: function (show) {
            this.$refs.q_overlay.run = show

        },
        startDeleteUser: async function () {
            this.$refs.dialog_component.show(undefined, { "title": "Delete", "description": "Are you sure you want to delete this preset?" })
        },
        edit: function () {
            this.$emit('edit', '')
            this.close()
        },
        deletePreset: async function () {
            try {
                var data = this.$store.getters["current/currentPreset"]
                if (!data || !data.objectId) {
                    showMessage("Failed to delete user", true)
                    return
                }

                if (!this.$refs.detailsDialog.classList.contains("showDrawer")) {
                    this.show()
                }
                this.showOverlay(true)
                toggleDisabableElements(true)

                var result = await axios.post(`${this.$store.getters["urls/adminBaseUrl"]}/presets/delete/${data.objectId}`)

                if (result.data.msg == "ok") {
                    this.close()
                    this.$emit('deleted', '')
                } else {

                    showMessage("Failed to delete user", true)
                }
                toggleDisabableElements(false)
                this.showOverlay(false)

            } catch (error) {
                this.showOverlay(false)
                toggleDisabableElements(false)
                showMessage("Failed to delete user", true)
            }
        },
        loadPreset: async function (data) {
            console.log("my sdfo asdf asf " + JSON.stringify(data))
            try {
                this.showOverlay(true)
                toggleDisabableElements(true)



                let resultData = this.$store.getters["current/currentPreset"]

                if (resultData) {


                    for (var key in resultData) {
                        var value = resultData[key];
                        if (key === "ACL") { continue }
                        else if (key === "objectId") {
                            this.currentUserId = value
                        } else if (key === "author") {

                            var authorResult = await axios.get(`${this.$store.getters["urls/adminBaseUrl"]}/users/${value.objectId}`)

                            this.presetDetails.push({
                                "key": "",
                                "title": "Author",
                                "value": authorResult.data.username
                            })
                            this.presetDetails.push({
                                "key": "",
                                "title": "Author Id",
                                "value": authorResult.data.objectId
                            })
                        } else if (key === "sections") {
                            this.presetDetails.push({
                                "key": "",
                                "title": "Scope",
                                "value": joinArrayToStr(value)
                            })
                        } else {

                            this.presetDetails.push({
                                "key": "",
                                "title": key == "name" ? "Title" : key,
                                "value": value
                            })
                        }

                    }
                    toggleDisabableElements(false)
                    this.showOverlay(false)
                }
            } catch (error) {
                this.showOverlay(false)
                toggleDisabableElements(false)
                showMessage("Failed to fetch preset", true)
            }

        }
    }
};
</script>
<style scoped>
.showDrawer {
    width: 400px !important;
    opacity: 1 !important;
}

.details-container {
    position: fixed;
    background-color: white;
    height: 100vh;
    width: 0;
    right: 0;
    top: 0;
    padding: 0px;
    z-index: 99999999999;
}

.button-container .content-container {
    padding-left: 8px !important;
    padding-right: 8px !important;
}

.drawer-header button:hover {
    background-color: var(--nav-current)
}

.drawer-header button {

    border-radius: 32px;
    line-height: 1;
    height: 32px;
    width: 10%;
}

.drawer-header button i {
    font-size: 1.4em;
}

.drawer-header {
    height: 32px;
    padding: 16px;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 20px;
}

.q-divider {
    margin-top: 16px;
    margin-bottom: 0px;
}

.drawer-content-container {
    height: 100%;
    width: 100%;
    overflow: auto;
}

.child-content-container p,
.child-content-container h4 {
    margin: 0;
}

.child-content-container h4 {
    font-family: main_font_bold;
    font-size: .9em;
}

.group-title-container {
    background-color: var(--theme-color-navbar-bg);

}

.q-layout-row {
    width: 100%;
}

.q-button {
    width: 100%;
    background-color: var(--q_red);
    color: white !important;
    border-radius: 8px;
}

.q-button p {
    color: white;
    margin-left: 8px
}
</style>
