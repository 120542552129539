<template>
    <div class="q-main-container q-display-flex-column q-align-content-stretch q-align-stretch ">
        <div class=" q-title-container q-display-flex-row q-item-animatable q-flex-center-items q-center">

            <a href="/">
                <h3> Gowant Writer</h3>
            </a>
        </div>
        <div class="nav-container">
            <ul>
                <li class="q-display-flex-row q-item-animatable q-flex-center-items " v-for="(section) in sections"
                    :key="section.key">
                    <i :class="section.icon"></i>
                    <RouterLink :to="section.url">{{ section.name }}</RouterLink>
                </li>
            </ul>

        </div>
    </div>
</template>

<script>
export default {
    name: "NavigationBarComponent",
    data() {
        return {
            sections: [

                {
                    key: 'key',
                    name: 'Users',
                    icon: 'ri-group-line',
                    url: '/console/users',

                },

                {
                    key: 'key',
                    name: 'Ai Models',
                    icon: 'ri-shapes-line',
                    url: '/console/aiModels',

                },
                {
                    key: 'key',
                    name: 'Presets',
                    icon: 'ri-pantone-line',
                    url: '/console/presets',

                },
                {
                    key: 'key',
                    name: 'Ai',
                    icon: 'ri-ai-generate',
                    url: '/console/ai',

                }


            ]
        }
    },
};
</script>
<style scoped>
.nav-container ul {
    list-style-type: none;
    margin: 0px;
    margin-top: 16px;
    padding: 0px;
}

li:has(> a.router-link-active) {
    background-color: var(--nav-current)
}

.nav-container ul li {
    padding-top: 12px;
    padding-bottom: 12px;
    border-radius: 8px;
    margin-left: 8px;
    margin-right: 8px;
}

ul li:hover {
    background-color: var(--nav-selected)
}

ul li {
    padding-left: 16px;
}

ul li a {
    padding-left: 12px;
    color: var(--gw_text_color);
    font-family: main_font_bold;
    font-size: .9em;
}

.nav-container {

    width: 100%;
    height: 100%;
}

.q-main-container {
    background-color: var(--theme-color-navbar-bg);

    overflow: hidden;
    min-height: 48px;
    border-right: 1px solid rgba(0, 0, 0, .12);
    width: 250px;
    height: 100vh;
    padding: 0 !important;
}

.q-layout-row {
    padding: 0 !important;
    width: inherit;
}

.q-col-6 {
    padding: 0 !important;
}

.q-title-container {
    border-bottom: 1px solid rgba(0, 0, 0, .12);
    height: 48px
}

.q-title-container h3 {
    font-family: main_font_bold;
    font-style: normal;

    margin-top: 18px;
    font-size: 1em;
    margin-bottom: 16px
}

.q-title-container img {
    width: 30px;
}
</style>
