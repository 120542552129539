<template>
    <div class=" q-display-flex-column  q-center q-flex-center-items" style="width: 100%;">
        <div class="main-content-container q-display-flex-column  q-center q-flex-center-items">
            <h2 class="q-align-self-left" style="margin-top: 0; margin-bottom: 32px;">AI</h2>
            <ContentHeaderComponent @searchItem="handleSearch" :controls="controls"
                searchPlaceHolder="Find Classification" @controlData="handleControlClick">
            </ContentHeaderComponent>

            <div :class="showPlaceHolderELement"
                class=" q-place-holder q-display-flex-column  q-center q-flex-center-items">

                <i class="ri-emotion-normal-line"></i>
                <p>Oops, No Ais yet, click "Add" to create a new Ai</p>
            </div>
            <table :class="showTableELement" style="visibility: visible;">
                <tr style="background-color:transparent;border-radius: 8px;" :key="tableKey">
                    <th v-for="(header) in headers" :key="header.key">
                        {{ header.name }}
                    </th>
                    <th style="width: 32px;">
                    </th>
                </tr>
                <tr data-can-be-disabled :class="currentPresetId == row.objectId ? 'current-row' : ''"
                    class="q-animatable" v-for="(row) in tableData" :key="row.objectId">
                    <td @click="viewUserDetails($event, row)">
                        {{ row.objectId }}
                    </td>
                    <td @click="viewUserDetails($event, row)">
                        {{ row.name }}
                    </td>
                    <!-- <td @click="viewUserDetails($event, row)">
                        {{ row.welcomeText }}
                    </td>
                    <td @click="viewUserDetails($event, row)">
                        {{ row.url }}
                    </td> -->
                    <td @click="viewUserDetails($event, row)">
                        {{ row.createdAt }}
                    </td>

                    <td @click="viewUserDetails($event, row)">
                        {{ row.updatedAt }}
                    </td>

                    <OptionsPopup @setCurrent="setCurrent" @controlData="handleRowControlClick" :controls="rowControls"
                        :rowData="row">
                    </OptionsPopup>

                </tr>
            </table>
            <ContentTableFooter ref="footerItem" :totalCount="count" @next="nextPage" @prev="prevPage"
                @limitChanged="loadAiModels" @queryReady="loadAiModels">
            </ContentTableFooter>
        </div>
        <EditAiTypeComponent :defaultUrls="defaultUrls" @edited="loadAiModels" ref="editAiDialog">

        </EditAiTypeComponent>
        <PreviewAiDrawer @edit="editPreset" @deleted="loadAiModels" ref="editDialog">
        </PreviewAiDrawer>
        <CreateAiType @created="loadAiModels" :defaultUrls="defaultUrls" ref="detailsDialog">
        </CreateAiType>
    </div>
</template>

<script>

import { showLoadingState, showMessage } from "../../../public/js/utils.js"
import ContentHeaderComponent from "../../components/admin/ContentHeaderComponent.vue"
import CreateAiType from "../../components/admin/CreateAiType.vue"
import PreviewAiDrawer from "../../components/admin/PreviewAiDrawer.vue"
import OptionsPopup from "../../components/admin/OptionsPopup.vue"
import ContentTableFooter from "../../components/admin/ContentTableFooter.vue"
import EditAiTypeComponent from "../../components/admin/EditAiTypeComponent.vue"

import axios from "axios";


axios.defaults.withCredentials = true;
export default {
    name: "AiComponentView",
    components: {
        CreateAiType,
        ContentHeaderComponent,
        PreviewAiDrawer,
        OptionsPopup,
        ContentTableFooter,
        EditAiTypeComponent
    },
    data() {
        return {
            currentPresetId: "",
            hasData: true,
            count: 0,
            tableKey: "",
            rowControls: [

                {
                    "key": 0,
                    "name": "Edit",
                    "toolTip": "Edit Classification",

                },
                {
                    "key": 1,
                    "name": "Delete",
                    "toolTip": "Delete Classification",

                },
            ],
            controls: [
                {
                    "key": 0,
                    "name": "Refresh",
                    "toolTip": "Refresh Classifications",
                    "icon": "ri-refresh-line"
                },
                {
                    "key": 1,
                    "name": "Add",
                    "toolTip": "Add Classifications",
                    "icon": "ri-add-circle-line"
                },
                {
                    "key": 2,
                    "name": "Edit",
                    "toolTip": "Edit Classifications",
                    "icon": "ri-pencil-line"
                },
                {
                    "key": 3,
                    "name": "Delete",
                    "toolTip": "Delete Classifications",
                    "icon": "ri-delete-bin-line"
                },
            ], headers: [
                { "name": "ObjectId", "key": "" },
                { "name": "Name", "key": "" },

                { "name": "Created At", "key": "" },
                { "name": "Updated At", "key": "" },
            ],
            tableData: [

            ],
            defaultUrls: [
                { key: 0, url: "http://api.gowwr.com/ai/generate/baidu" },
                {
                    key: 1, url: "http://api.gowwr.com/ai/generate/moonshot"
                },
                {
                    key: 2, url: "http://api.gowwr.com/ai/generate/alibaba"
                },
                { key: 3, url: "http://api.gowwr.com/ai/generate/spark" }
            ]
        }
    },
    props: {
        users: [],
    },
    computed: {
        isLoadingUser() {
            return this.$store.getters["admin/isReloadingLoadingUser"];
        },
        isLoggedIn() {
            return this.$store.getters["admin/isLoggedIn"];
        },
        showPlaceHolderELement() {
            return !this.hasData ? "showPlaceHolder" : ""
        },
        showTableELement() {
            return this.hasData ? "" : "hideTable"
        },
        currentPresetData() {
            return this.$store.getters["current/currentAi"];
        },
        isCurrentPreset() {
            var data = this.$store.getters["current/currentAi"]
            console.log("mi == " + "  " + data.objectId)

            if (data.objectId != undefined && this.currentPresetId == data.objectId)
                return "current-row"
            return ""
        }

    },
    watch: {
        isLoadingUser(new_, old_) {
            if (new_ != old_) {
                this.loadAiModels();
            }
        },
        currentPresetData(new_, old_) {

            if (new_ != old_) {
                this.setCurrentPresetId();
            }
        },

    },
    methods: {
        nextPage: function () {
            this.$refs.footerItem.generateQuery(true)
        },
        prevPage: function () {
            this.$refs.footerItem.generateQuery(false)
        },
        setCurrentPresetId: function () {
            var data__ = this.$store.getters["current/currentAi"]

            this.currentPresetId = data__ && data__.objectId ? data__.objectId : ""

        },
        handleSearch: function (event, data) {

            console.trace()
            if (!data || data === "") {
                this.loadAiModels()
            } else {
                this.loadAiModels({
                    query: `where={"name":"${data}"}`
                })
            }
        },
        loadAiModels: async function (data) {
            // reset current preset
            this.$store.dispatch("current/setCurrentAi", {})
            console.log("lsoisdofias fa " + this.$store.getters["urls/baseUrl"])
            if (!this.isLoggedIn)
                return

            let url = `${this.$store.getters["urls/baseUrl"]}/ai/all/limit=${499}?readFromcache=false`

            if (data) {
                if (data.limit)
                    url = `${this.$store.getters["urls/baseUrl"]}/ai/all/limit=${data.limit}?readFromcache=false`
                else if (data.query)
                    url = `${this.$store.getters["urls/baseUrl"]}/ai/all/${data.query}?readFromcache=false`
            }
            console.log("my sdf sdf asdf asf " + url)
            try {
                showLoadingState(true)
                var result = await axios.get(
                    url,
                );
                showLoadingState(false)


                this.tableData = result.data.results;
                console.log(",ud af a0sdf asdf asd f" + this.tableData.length)
                this.hasData = this.tableData.length > 0
                console.log("is rasdpfsdpf asdf ")
                this.forceRerender()
                if (data && data.isNext != undefined) {
                    if (data.isNext) {
                        this.$refs.footerItem.increamentNextPage()
                    } else {
                        this.$refs.footerItem.decreamentNextPage()
                    }
                }

            } catch (error) {
                showLoadingState(false)
                showMessage("Failed to load classification", true)
            }
            this.countModels()
        },
        forceRerender: function () {
            console.log("is updatinf " + this.tableKey)
            this.tableKey += 1
        },
        countModels: async function () {

            if (!this.isLoggedIn)
                return

            try {
                var result = await axios.get(
                    `${this.$store.getters["urls/baseUrl"]}/ai/all/limit=0&count=1?readFromcache=false`,
                );
                this.count = result.data.count;

            } catch (error) {
                console.warn("Failed to count ai models, retrying " + error)
                setTimeout(() => {
                    this.countModels()
                }, 10000);
            }
        },
        viewUserDetails: function (event, data) {

            this.$store.dispatch("current/setCurrentAi", data)
            this.$refs.editDialog.show()
        },
        setCurrent: function (event, row) {

            var data_ = this.tableData.filter(a => {
                return a.objectId === row.objectId
            })

            this.$store.dispatch("current/setCurrentAi", data_.length > 0 ? data_[0] : row)
        },
        handleRowControlClick: function (event, data) {
            console.log(" mysdfu asdfasdf " + JSON.stringify(data))
            if (this.currentPresetData.objectId) {
                if (data.key === 0) {
                    this.$refs.editAiDialog.show()
                } else if (data.key === 1) {
                    this.$refs.editDialog.startDeleteUser()
                }
            } else {
                showMessage("Please select a classification ", false)
            }
        },
        handleControlClick: function (event, data) {
            console.log(" mysdfu asdfasdf " + JSON.stringify(data))
            if (data.key === 1) {
                this.createPreset()
            } else if (data.key === 0) {
                this.loadAiModels()
            } else if (data.key === 2) {
                if (this.currentPresetData.objectId) {
                    this.$refs.editAiDialog.show()
                } else {
                    showMessage("Please select a classification ", false)
                }
            } else if (data.key === 3) {

                if (this.currentPresetData.objectId) {
                    this.$refs.editDialog.startDeleteUser()
                } else {
                    showMessage("Please select a classification  ", false)
                }
            }
        },
        editPreset: function () {
            this.$refs.editAiDialog.show()
        },
        createPreset: function () {
            this.$refs.detailsDialog.show()
        }
    },
    mounted: function () {

        this.loadAiModels();
    },

};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.q-place-holder {
    max-width: 1000px;
    background-color: transparent;
    width: 500px;
    height: 500px;
    display: none;
}

.q-place-holder p {
    font-size: 1em;
    opacity: .5;
    text-align: center;
    max-width: 250px;
}

.q-place-holder i {
    font-size: 7em;
    opacity: .3;
}

.hideTable {
    visibility: hidden !important;
}

.showPlaceHolder {
    display: flex !important;
}
</style>
