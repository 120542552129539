<template>
    <dialog aria-modal="true" aria-label="user Details"
        class=" q-animatable  q-delay-fast elevate-5 details-container  q-display-flex-column " ref="detailsDialog">
        <progress-overlay-element ref="q_overlay"></progress-overlay-element>

        <div class="drawer-header  q-display-flex-row q-align-stretch">
            <h3 style="margin: 0px;width: 100%;">Create Ai Model</h3>
            <button data-can-be-disabled @click="refreshSupportedAis"
                class=" q-display-flex-column q-center q-flex-center-items" title="Refresh Supported AIs">
                <i class="ri-refresh-line"></i>
            </button>
            <button data-can-be-disabled @click="close" class=" q-display-flex-column q-center q-flex-center-items"
                title="close">
                <i class="ri-close-circle-line q-align-self-right"></i>
            </button>

        </div>
        <div class="q-divider"></div>

        <div class="drawer-content-container">

            <div class=" q-layout-container q-progress-overlay-container content-container">

                <div class="q-layout-row  child-content-container">
                    <form action="#" ref="form" method="post" style="width: 100%;">

                        <fieldset>
                            <label for="ai">Choose target AI Type</label>
                            <div class="q-password-field">

                                <select name="ais" ref="supportedAisSelector">
                                    <option v-for="(item) in supportedAisList" :key="item.key" :value="item.name"
                                        :data-key="item.key">
                                        {{ item.name }}</option>

                                </select>
                            </div>

                        </fieldset>

                        <form-input ref="modelElement" type="text" name="text" placeHolder="Ai Model Name"
                            label="Enter AI Model Name"></form-input>
                        <form-input ref="modelNameElement" type="text" name="text" placeHolder="Display Name"
                            label="Model Name"></form-input>

                        <form-input ref="cost" type="text" name="int" placeHolder="Cost"
                            label="Cost Per 1000 tokens"></form-input>

                        <div data-can-be-disabled class="q-custom-dropdown">

                            <input data-can-be-disabled ref="urlInput" type="url" name="text" placeHolder="Url" />
                            <select ref="urlSelectRef">
                                <option v-for="(item) in defaultUrls" :key="item.key">{{ item.url }}</option>

                            </select>
                        </div>



                        <div style="margin-top: 16px;margin-bottom: 16px;" class="q-display-flex-row">
                            <input ref="aiModelState" id="modelState" type="checkbox" checked>
                            <label for="modelState" style="margin-left: 8px">Ai model enabled</label>
                        </div>
                        <div class=" q-form-button-container q-display-flex-column  ">
                            <button ref="createButton" data-can-be-disabled type="submit" name="button"
                                class="btn anchor-button   " style="align-self:stretch;">Create</button>
                        </div>
                    </form>
                </div>



            </div>
        </div>
    </dialog>
</template>

<script>
import { showMessage, toggleDisabableElements } from "../../../public/js/utils.js"

import axios from "axios";
axios.defaults.withCredentials = true;
export default {
    name: "CreateAiModelComponent",
    props: ["defaultUrls"],
    components: {

    },
    data() {
        return {
            supportedAisList: [],
            userDetails: [],
            currentUserId: ""
        }
    },
    mounted() {
        this.$refs.urlSelectRef.addEventListener("change", () => {
            console.log("os af asdf as;f ;asf;a sd;f ")
            this.$refs.urlInput.value = this.$refs.urlSelectRef.value;
            this.$refs.urlInput.focus()
        })
        this.$refs.form.addEventListener("submit", e => {

            e.preventDefault()
            this.createPreset()
        })
    },
    methods: {
        show: async function () {
            this.$refs.detailsDialog.showModal()
            this.refreshSupportedAis()
        },
        close: function () {
            this.$refs.detailsDialog.close()
        },
        showOverlay: function (show) {
            this.$refs.q_overlay.run = show
            toggleDisabableElements(show)
        },
        refreshSupportedAis: async function () {
            this.showOverlay(true)
            try {
                this.supportedAisList = []
                var result = await axios.get(`${this.$store.getters["urls/baseUrl"]}/ai/all/limit=499?readFromcache=false`)

                this.showOverlay(false)
                console.log("my eizlffa sfas " + JSON.stringify(result))
                if (result.data.results.length !== 0) {
                    result.data.results.forEach((element) => {
                        console.log("adding is " + JSON.stringify(element))
                        this.supportedAisList.push({
                            key: element.objectId,
                            name: element.name
                        })
                    });
                } else {

                    throw ("Failed to fetch ais")
                }
            } catch (error) {
                console.log("mysd f asodf as f   " + error)
                this.showOverlay(false)
                showMessage("Failed to fetch supported Ais", true)
            }
        },
        createPreset: async function () {
            try {
                this.showOverlay(true)
                var e = this.$refs.supportedAisSelector

                let data = {
                    modelName: this.$refs.modelElement.inputValue,
                    name: this.$refs.modelNameElement.inputValue,
                    pointCost: this.$refs.cost.inputValue,
                    url: this.$refs.urlInput.value,
                    aiType: e.options[e.selectedIndex].getAttribute("data-key"),
                    enabled: this.$refs.aiModelState.checked
                }

                console.log("my sdpf asdf asdf asdf " + JSON.stringify(data) + "  " + e.options[e.selectedIndex].getAttribute("data-key"))

                var result = await axios.post(`${this.$store.getters["urls/adminBaseUrl"]}/ai/models/create`, data)


                if (result.data.objectId != "") {
                    this.close()
                    this.$emit('created', '')
                    // reset inputs
                    this.$refs.modelElement.inputElement.value = ""
                    this.$refs.modelNameElement.inputElement.value = ""
                    this.$refs.cost.inputElement.value = ""

                } else {
                    showMessage("Failed to create preset", true)
                }

                this.showOverlay(false)

            } catch (error) {
                console.log("my errpr " + error)
                this.showOverlay(false)

                showMessage("Failed to create preset", true)
            }



        }
    }
};
</script>
<style scoped>
.showDrawer {
    width: 400px !important;
    opacity: 1 !important;
}

.details-container {
    background-color: white;
    width: 600px;
    right: 0;
    top: 0;
    padding: 0px;
}

.drawer-header button:hover {
    background-color: var(--nav-current)
}

.drawer-header button {

    border-radius: 32px;
    line-height: 1;
    height: 32px;
    width: 10%;
}

.drawer-header button i {
    font-size: 1.2em;
}

.drawer-header {
    height: 32px;
    padding: 16px;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 20px;
}

.q-divider {
    margin-top: 16px;
    margin-bottom: 0px;
}

.drawer-content-container {
    height: 100%;
    width: 100%;
    overflow: auto;
}



.child-content-container p,
.child-content-container h4 {
    margin: 0;
}

.child-content-container h4 {
    font-family: main_font_bold;
    font-size: .9em;
}

.group-title-container {
    background-color: var(--theme-color-navbar-bg);

}

.q-layout-row {
    width: 100%;
}

.q-button {
    width: 100%;
    background-color: var(--q_red);
    color: white !important;
}

.q-button p {
    color: white;
    margin-left: 8px
}
</style>
