import { createStore } from "vuex";
import UserModule from "./user.module.js";
import UrlModule from "./urls.module.js";
import adminModule from "./admin.module.js";
import selectedModule from "./selected.modules.js";
import stateModule from "./status.module.js";
export default createStore({
  modules: {
    user: UserModule,
    urls: UrlModule,
    admin: adminModule,
    current: selectedModule,
    status: stateModule
  }
});
