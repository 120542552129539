<template>
    <dialog aria-modal="true" aria-label="user Details"
        class=" q-animatable q-delay-fast elevate-5 details-container  q-display-flex-column " ref="detailsDialog">
        <progress-overlay-element ref="q_overlay"></progress-overlay-element>

        <div class="drawer-header  q-display-flex-row q-align-stretch">
            <h3 style="margin: 0px;width: 100%;">Edit</h3>
            <button data-can-be-disabled @click="close" class=" q-display-flex-column q-center q-flex-center-items"
                title="close">
                <i class="ri-close-circle-line q-align-self-right"></i>
            </button>

        </div>
        <div class="q-divider"></div>

        <div class="drawer-content-container">

            <div class=" q-layout-container q-progress-overlay-container content-container">

                <div class="q-layout-row  child-content-container">
                    <form action="#" ref="form" method="post" style="width: 100%;">
                        <form-input ref="inputElement" type="text" name="text" placeHolder="Title"
                            label="Enter Title"></form-input>
                        <!-- 
                        <form-input ref="welcomeInput" type="text" name="text" placeHolder="Text"
                            label="Enter Welcome Text"></form-input>
                        <form-input ref="urlInput" type="url" name="text" placeHolder="Url"
                            label="Enter Url"></form-input> -->

                        <div class=" q-form-button-container q-display-flex-column  ">
                            <button ref="createButton" data-can-be-disabled type="submit" name="button"
                                class="btn anchor-button   " style="align-self:stretch;">Edit</button>
                        </div>
                    </form>
                </div>



            </div>
        </div>
    </dialog>
</template>

<script>
import { showMessage, toggleDisabableElements } from "../../../public/js/utils.js"

import axios from "axios";
axios.defaults.withCredentials = true;
export default {
    name: "EditAiTypeComponent",
    components: {

    },
    data() {
        return {

            supportedPages: [
            ]
        }
    },
    mounted() {

        this.$refs.form.addEventListener("submit", e => {
            e.preventDefault()
            console.log("msdf " + e)
            this.editItem()
        })
    },
    methods: {
        updateCheckedItems: function () {
            var data = this.$store.getters["current/currentAi"]

            if (data) {
                this.$refs.inputElement.inputElement.value = data.name
                // if (data.welcomeText)
                //     this.$refs.welcomeInput.inputElement.value = data.welcomeText
                // if (data.url)
                //     this.$refs.urlInput.inputElement.value = data.url
            }
        },
        show: async function () {
            this.$refs.detailsDialog.showModal()
            this.updateCheckedItems()
        },

        editItem: async function () {
            try {

                this.showOverlay(true)
                var currentPreset = this.$store.getters["current/currentAi"]
                if (this.$refs.inputElement.inputValue === "") {
                    return;
                }
                let data = {
                    name: this.$refs.inputElement.inputValue,
                    // url: this.$refs.urlInput.inputValue,
                    // welcomeText: this.$refs.welcomeInput.inputValue
                }
                var result = await axios.post(`${this.$store.getters["urls/adminBaseUrl"]}/ai/edit/${currentPreset.objectId}`, data)

                if (result.data.updatedAt != "") {
                    this.close()
                    this.$emit('edited', '')
                } else {
                    showMessage("Failed to edit Ai", true)
                }

                this.showOverlay(false)

            } catch (error) {
                console.log("my errpr " + error)
                this.showOverlay(false)

                showMessage("Failed to edit Ai", true)
            }

        },
        close: function () {

            this.$refs.detailsDialog.close()
        },
        showOverlay: function (show) {
            this.$refs.q_overlay.run = show
            toggleDisabableElements(show)
        },


    }
};
</script>
<style scoped>
dialog {
    position: absolute;
}

.showDrawer {
    width: 400px !important;
    opacity: 1 !important;
}

.details-container {

    background-color: white;

    width: 600px;
    right: 0;
    top: 0;
    padding: 0px;

}

.drawer-header button:hover {
    background-color: var(--nav-current)
}

.drawer-header button {

    border-radius: 32px;
    line-height: 1;
    height: 32px;
    width: 10%;
}

.drawer-header button i {
    font-size: 1.4em;
}

.drawer-header {
    height: 32px;
    padding: 16px;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 20px;
}

.q-divider {
    margin-top: 16px;
    margin-bottom: 0px;
}

.drawer-content-container {
    height: 100%;
    width: 100%;
    overflow: auto;
}



.child-content-container p,
.child-content-container h4 {
    margin: 0;
}

.child-content-container h4 {
    font-family: main_font_bold;
    font-size: .9em;
}

.group-title-container {
    background-color: var(--theme-color-navbar-bg);

}

.q-layout-row {
    width: 100%;
}

.q-button {
    width: 100%;
    background-color: var(--q_red);
    color: white !important;
}

.q-button p {
    color: white;
    margin-left: 8px
}
</style>
