<template>
    <div class="content-footer  q-display-flex-row q-center q-flex-center-items "
        style="padding-top: 16px;padding-bottom: 16px">
        <p>Total {{ totalCount }} items </p>

        <!-- <p style="margin-left: 16px;">Items per page:</p> -->
        <fieldset style="display: none;">
            <div class="q-password-field">
                <select name="ais" ref="itemPerPage">

                    <option v-for="(item) in itemPerPage" :key="item.key" :value="item.count">
                        {{ item.count }} </option>

                </select>
                <span class=" q-display-flex-row q-center q-flex-center-items "><i
                        class="ri-arrow-down-s-line"></i></span>
            </div>
        </fieldset>
        <div class="page-btn-container q-center q-flex-center-items q-display-flex-row " style="display: none;">
            <button :disabled="!canPrev" class="hoverable" title="Previous page" @click="prevPage"> <i
                    class="ri-arrow-left-s-line"></i></button>
            <span>{{ currentPageIndex }} of {{ totalPageCount(totalCount) }}</span>
            <button :disabled="!canNext" @click="nextPage" class="hoverable" title="Next page"> <i
                    class="ri-arrow-right-s-line"></i></button>

        </div>
    </div>
</template>

<script>
export default {
    name: "ContentTableFooter",
    props: ["totalCount"],
    computed: {
        currentCurrentPageIndex() {
            return this.currentPageIndex
        },
        canNext() {
            return this.hasNext
        },
        canPrev() {
            return this.hasPrev
        }
        , onOverallTotalPageCount() {
            return this.overallTotalPageCount
        }
    },
    watch: {
        currentCurrentPageIndex(new_, old_) {
            if (new_ != old_) {
                this.updateNextAndPrevButtons();
            }
        },
        onOverallTotalPageCount(new_, old_) {
            if (new_ != old_) {
                this.updateNextAndPrevButtons();
            }
        }
    },
    mounted() {
        this.$refs.itemPerPage.addEventListener("change", () => {
            this.updatenumberOfItemsPerPage()
        })
        this.updatenumberOfItemsPerPage()
    },
    methods: {
        totalPageCount(totalCount) {
            if (!totalCount || this.numberOfItemsPerPage === 0) {
                return 1
            }
            var result = (totalCount / this.numberOfItemsPerPage)

            if (result % 1) {
                this.overallTotalPageCount = Math.floor(result) + 1
            } else {
                this.overallTotalPageCount = result
            }
            return this.overallTotalPageCount
        },
        updatenumberOfItemsPerPage: function () {
            var e = this.$refs.itemPerPage
            var f = Number(e.options[e.selectedIndex].text)

            this.numberOfItemsPerPage = f
            this.$emit('limitChanged', { limit: f })
        },
        nextPage: function () {
            this.$emit('next')
        },
        prevPage: function () {
            this.$emit('prev')
        },

        generateQuery: function (isNext) {
            var skip = this.numberOfItemsPerPage * (isNext ? (this.currentPageIndex + 1) : (this.currentPageIndex - 1))
            var query = isNext ? `limit=${this.numberOfItemsPerPage}&skip=${skip}` : `limit=${this.numberOfItemsPerPage}&skip=-${skip}`
            this.$emit('queryReady', { query: query, isNext: isNext })
        },

        increamentNextPage: function () {
            console.log("is requestung add page " + this.currentPageIndex + "  " + this.overallTotalPageCount)
            if (this.currentPageIndex < this.overallTotalPageCount) {
                console.log("is adding page " + this.currentPageIndex)
                this.currentPageIndex++
            }

        },
        decreamentNextPage: function () {
            if (this.currentPageIndex > 1)
                this.currentPageIndex--

        },
        updateNextAndPrevButtons: function () {
            console.log("updaitng index " + this.currentPageIndex + "  " + this.overallTotalPageCount)
            console.log("begre sfgs fg " + this.hasNext + "  " + this.hasPrev)
            this.hasNext = this.currentPageIndex < this.overallTotalPageCount
            this.hasPrev = this.currentPageIndex > 1
            console.log("is tuasfasod foasdf   " + this.hasNext + "  " + this.hasPrev)

        }
    },
    data() {
        return {
            hasNext: false,
            hasPrev: false,
            currentPageIndex: 1,
            numberOfItemsPerPage: 0,
            overallTotalPageCount: 1,
            itemPerPage: [

                {
                    "key": "",
                    "count": 10
                },
                {
                    "key": "",
                    "count": 20
                },
                {
                    "key": "",
                    "count": 50
                },
                {
                    "key": "",
                    "count": 100
                },

            ]
        }
    }
};
</script>
<style scoped>
.content-footer {
    background-color: #e7edf6;
    width: 100%;
    padding: 0px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
}

select {
    background-color: transparent;
    border: 0;
    position: relative;
}

.q-password-field {
    position: relative;
}

.q-password-field span {
    content: "sd";
    position: absolute;
    width: 32px;
    height: 32px;
    background-color: transparent;
    z-index: 99;
    right: 0;
    text-align: center;
    top: 0;
    pointer-events: none;
}

.q-password-field span i {
    font-size: 1.3em;
}

.content-footer p,
.content-footer span {
    font-size: .9em !important;
    margin: 0;
}

.content-footer fieldset {
    border: 0;
    font-size: .9em !important;
}

.content-footer select {
    font-size: .9em !important;
}

.page-btn-container button {
    background-color: transparent;
    margin-left: 8px;
    margin-right: 8px;
}
</style>
