<template>
  <div>
    <progress-overlay-element ref="q_root_overlay" id="q-root-overlay"></progress-overlay-element>
    <popup-dialog-element ref="popUpDialog" id="popUpDialog"></popup-dialog-element>
    <header id="gw-main-header" data-auth-depenedant>


      <!--Small screen nav-->
      <nav class="q-item-blur q-nav-small">
        <div class="q-nav-content-overlay q-display-flex-column">

          <div class="q-nav-header-container q-display-flex-row q-align-stretch">


            <div class="q-nav-controls-container q-display-flex-column q-center">
              <a class="q-navbar-toggler q-display-flex-column q-center" href="javascript:void(0);"><span
                  class="ri-menu-line"></span></a>
            </div>
            <div class="q-nav-controls-container q-display-flex-column q-center">
              <a href="/" class="q-nav-logo-container q-display-flex-column q-flex-center-items q-center">
                <!--App Icon Begin-->
                <img src="images/gw_icon.png" style="width: 30px;" alt="Gowant writer icon">
                <!--App Icon End--></a>
            </div>
            <div class="q-nav-controls-container q-display-flex-column q-center">
              <small-device-auth-container ref="smallDeviceAuth"></small-device-auth-container>
            </div>
          </div>
        </div>
      </nav>

      <!---Large screens nave-->

      <nav class="q-nav-large">
        <div class="q-nav-content-overlay">
          <div class="q-section">
            <div class="q-nav-header-container q-display-flex-row q-align-stretch">
              <div class="q-nav-controls-container q-display-flex q-center">
                <ul class="q-nav-ul q-display-flex-row q-item-animatable q-align-self-right q-center q-nav-index-url"
                  style="margin-top: 0px">
                  <li class="q-display-flex q-center" style="padding: 0">
                    <a href="/" class="q-nav-logo-container q-display-flex-row q-flex-center-items q-flex-end">
                      <!--App Icon Begin-->
                      <img src="images/gw_icon.png" style="width: 30px; height: 30px; object-fit: contain;"
                        alt="Gowant writer icon" />
                      <!--App Icon End-->
                    </a>
                  </li>
                  <li>高文特 编剧</li>
                  <li class="q-divider-vertical"></li>
                  <li class="q-semi-bold">轮廓</li>
                </ul>
              </div>

              <div class="q-nav-controls-container q-auth-container q-display-flex q-center">
                <UserAvatarContainer></UserAvatarContainer>

              </div>
            </div>
          </div>
        </div>
      </nav>
    </header>

    <main data-auth-depenedant class="q-progress-overlay-container">
      <progress-overlay-element id="progress_overlay"></progress-overlay-element>

      <!--Start registration features-->

      <section class="q-desktop-section q-grayed-backgroud"
        style="padding-top: 0px !important; padding-bottom: 32px !important">
        <div class="q-section q-has-shaded-bg">
          <div class="q-layout-container q-profile-box">
            <div class="q-layout-row">
              <div class="q-col-sm-12 q-col-md-12 q-col-lg-12 q-col-xl-12 q-display-flex-column flow-center q-center">
                <div style="width: 100%">
                  <h2>您的信息</h2>
                  <user-points></user-points>
                </div>

                <div class="q-profile-container" style="margin-top: 32px"></div>
                <!-- <div class="q-profile-photo-container q-display-flex-row q-align-stretch">


                  <div class="q-profile-photo-img-container q-display-flex flow-center q-center">
                    <i class="ri-account-circle-line"></i>
                    <img ref="user_photo" src="images/bgs.jpg" />
                  </div>

                  <div class="q-banner-content-container q-display-flex-column q-center">
                    <p>用照片个性化您的帐户。</p>
                    <input id="file" accept="image/png, image/jpeg, image/jpg" type="file" ref="file" />
                    <div class="q-display-flex-row">
                      <button ref="buttonFile" data-can-be-disabled
                        class="q-button q-photo-chooser q-display-flex flow-center q-center q-flex-center-items"
                        style="color: var(--gw_text_color);margin-right:8px ;">
                        <span>改变</span>
                      </button>
                      <button ref="resetFile" data-can-be-disabled
                        class="q-button q-photo-chooser q-display-flex flow-center q-center q-flex-center-items"
                        style="color: var(--gw_text_color)">
                        <span>重置</span>
                      </button>
                    </div>
                  </div>
                </div> -->

                <profile-component style="
                    width: 100%;
                    background-color: white;
                    border-radius: 32px;
                    border: 1px solid rgba(0, 0, 0, 0.12);
                  " ref="profileComponent"></profile-component>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
    <footer id="q-profile-footer">
      <div class="q-display-flex-column flow-center q-center" style="width: 100%">
        <legal-component></legal-component>
      </div>
    </footer>
  </div>
</template>

<script>
import LegalComponent from "./../components/LegalComponent.vue";
import UserPoints from "./../components/UserPoints.vue"
import axios from "axios";
import $ from "jquery";
import { formatContact } from "../../public/js/utils.js"

import UserAvatarContainer from "./../components/UserAvatarContainer.vue"
axios.defaults.withCredentials = true;

export default {
  name: "ProfileView",
  props: {
    msg: String,
  },
  components: {
    LegalComponent, UserPoints, UserAvatarContainer
  },

  computed: {
    isLoadingUser() {
      return this.$store.getters["user/isReloadingLoadingUser"];
    },
    isLoggedIn() {
      return this.$store.getters["user/isLoggedIn"];
    },
    username() {
      return this.$store.getters["user/username"];
    },

    photoUrl() {
      return this.$store.getters["user/photoUrl"];
    },
    useremail() {
      return this.$store.getters["user/email"];
    },
    phone() {
      return formatContact(this.$store.getters["user/phone"]);
    },
    status() {
      return this.$store.getters["user/status"];
    },
  },
  mounted: function () {
    this.loadUser();
    // this.$refs.buttonFile.addEventListener("click", () => {
    //   this.$refs.file.click();
    // });
    // this.$refs.resetFile.addEventListener("click", () => {
    //   this.resetUserPhoto();
    // });
    // this.$refs.file.addEventListener("change", (event) => {
    //   if (event.target.files && event.target.files[0]) {
    //     this.uploadPhoto(event.target.files[0]);
    //     this.$refs.file.value = null
    //   }
    // });


    this.$refs.profileComponent.addEventListener(
      "profileDataChanged",
      (event) => {
        this.updateUser(event.detail);
      }
    );

  },
  watch: {
    isLoadingUser(new_, old_) {
      if (new_ != old_) {
        this.loadUser();
        this.updateNavItems();
      }
    },
    username(new_, old_) {
      if (new_ != old_) {
        this.$refs.profileComponent.setElementValue(
          new_,
          window.inputType.NAME
        );
        this.updateNavItems();
      }
    },
    useremail(new_, old_) {
      if (new_ != old_) {
        this.$refs.profileComponent.setElementValue(
          new_,
          window.inputType.EMAIL
        );
        this.updateNavItems();
      }
    },

    phone(new_, old_) {

      if (new_ != old_) {


        this.$refs.profileComponent.setElementValue(
          new_,
          window.inputType.CONTACT
        );
        this.updateNavItems();
      }
    },
  },
  methods: {
    resetUserPhoto: async function () {

      try {
        this.toggleDisabableElements(true);
        await axios.get(
          `${this.$store.getters["urls/baseUrl"]}/user/resetPhoto`,
        );

        this.showMessage("Updated", false)
        this.toggleDisabableElements(false);
        this.$store.dispatch("user/reloadUser", {
          url: `${this.$store.getters["urls/baseUrl"]}/checkSession/`,
          fetch: `${this.$store.getters["urls/baseUrl"]}/user/me`,
        });

      } catch (e) {
        this.toggleDisabableElements(false);
        this.showMessage("Failed to reset profile photo", true)
      }
    },
    loadUser: function () {
      console.log("musdfa sdfasdf sf " + this.$refs.q_root_overlay)
      this.$refs.q_root_overlay.run = this.isLoadingUser;

      if (this.isLoadingUser) return;
      console.log("my stayadf asdf  f " + this.status + "   " + this.$store.getters["user/tempData"])
      if (this.isLoggedIn) {

        this.$refs.profileComponent.setElementValue(
          this.username,
          window.inputType.NAME
        );
        this.$refs.profileComponent.setElementValue(
          this.useremail,
          window.inputType.EMAIL
        );
        this.$refs.profileComponent.setElementValue(
          this.phone,
          window.inputType.CONTACT
        );

        // this.$refs.user_photo.src = this.photoUrl
      } else {
        //  if (this.status == 404 || this.status == 400) {
        this.$router.push("/login/user");
        // redirect to home

        //} else {
        //  this.showMessage("An error ocurred, please try refreshing the page", true);

        // }
      }
      this.updateNavItems();
    },

    updateNavItems: function () {

      this.$refs.smallDeviceAuth.userData = this.$store.getters["user/user"];
      this.$refs.smallDeviceAuth.isSignedIn = this.isLoggedIn;
    },


    verify: async function () {
      this.toggleDisabableElements(true);
      try {
        this.$refs.q_root_overlay.run = true;
        var data = {
          useremail: this.$store.getters["user/email"],
        };

        var result = await axios.post(
          `${this.$store.getters["urls/baseUrl"]}/verify`,
          data
        );


        if (
          result.data != null &&
          result.data.msg != undefined &&
          result.data.msg != null &&
          result.data.msg == "ok"
        ) {
          this.showMessage("Email link successfully sent", false);
        } else {

          this.showMessage("Failed to send email link, please try again", true);
        }

        this.$refs.q_root_overlay.run = false;
      } catch (e) {

        this.$refs.q_root_overlay.run = false;
      }
    },

    updateUser: async function (userData) {

      this.toggleDisabableElements(true);
      try {
        var data = {};

        switch (userData.type) {
          case window.inputType.NAME:
            data.username = userData.primaryValue;
            data.password = userData.secondaryValue;
            data.oldusername = this.username
            break;
          case window.inputType.EMAIL:
            data.useremail = userData.primaryValue;
            data.password = userData.secondaryValue;
            data.username = this.$store.getters["user/username"];
            break;
          case window.inputType.CONTACT:
            data.phone = "+" + userData.primaryValue;
            break;
          case window.inputType.DELETE:
            data.password = userData.primaryValue;
            data.username = this.$store.getters["user/username"];
            break;
        }


        var result;
        if (userData.type === window.inputType.DELETE) {
          console.log("is deleted ")
          result = await axios.post(
            `${this.$store.getters["urls/baseUrl"]}/user/delete/`,
            data
          );
          console.log("dfasd fasf " + JSON.stringify(result))
        } else {
          console.log("udate user " + JSON.stringify(data) + "  " + (`${this.$store.getters["urls/baseUrl"]}/user/update`))
          result = await axios.put(
            `${this.$store.getters["urls/baseUrl"]}/user/update`,
            data
          );
          console.log("udate user " + JSON.stringify(result))
        }

        this.toggleDisabableElements(false);
        if (
          result.data != null &&
          result.data.updatedAt != undefined &&
          result.data.updatedAt != null
        ) {
          this.$store.dispatch("user/reloadUser", {
            url: `${this.$store.getters["urls/baseUrl"]}/user/checkSession/`,
            fetch: `${this.$store.getters["urls/baseUrl"]}/user/me`,
          });

        } else {
          if (
            userData.type === window.inputType.DELETE &&
            result.data.msg != undefined
          ) {
            this.$router.push("/signout");
          } else {

            if (result.data.error) this.showMessage(result.data.error, true);
            else
              this.showMessage(
                "Failed to update details, please try again",
                true
              );
          }
        }
      } catch (e) {

        // operation failed
        this.toggleDisabableElements(false);
        this.showMessage("Failed to update details, please try again");

      }
    },


    uploadPhoto: async function (data) {

      try {

        this.toggleDisabableElements(true);

        var formData = new FormData();
        formData.append("file", data);

        await axios.post(
          `${this.$store.getters["urls/baseUrl"]}/user/updatePhoto/`,
          formData,
          { headers: { ContentType: "multipart/form-data" } }
        );

        console.log("i ad osado fasodf issdf sdf f")

        this.showMessage("Updated", false)
        this.toggleDisabableElements(false);

        this.$store.dispatch("user/reloadUser", {
          url: `${this.$store.getters["urls/baseUrl"]}/checkSession/`,
          fetch: `${this.$store.getters["urls/baseUrl"]}/user/me`,
        });

      } catch (e) {
        this.toggleDisabableElements(false);
        this.showMessage("Failed to update photo", true)
      }
    },

    showMessage: function (text, isError) {
      this.$refs.popUpDialog.showDialog(text, isError);
    },

    toggleDisabableElements: function (isDisabled) {
      this.$refs.q_root_overlay.run = isDisabled;
      var items = $("[data-can-be-disabled]");

      items.each(function () {
        if (isDisabled) $(this).attr("disabled", "disabled");
        else $(this).removeAttr("disabled");
      });
    },
  },
};
</script>
<style scoped>
.q-text-container {
  padding-right: 32px;
}

.q-credit-span {
  position: absolute;
  top: 95%;
  font-size: 0.6em;
}

.q-overlay {
  position: absolute;
  top: 0%;
  background-color: rgba(1, 1, 1, 0.1);
  width: 100%;
  height: 100%;
}

.q-profile-photo-container {
  background-color: white;
  padding: 32px;
  margin-bottom: 32px;
  margin-top: 8px;
  width: 100%;
  border-radius: 32px;
  border: 1px solid rgba(0, 0, 0, 0.12);
}

.q-profile-photo-img-container {
  background-color: #f8fdf8;
  border: 1px solid rgba(0, 0, 0, 0.12);
  min-width: 32px;
  min-height: 32px;
  max-height: 32px;
  border-radius: 50px;

}

.q-profile-photo-img-container img {
  width: 100px;
  height: inherit;
  border-radius: 50px;
  z-index: 99;
  object-fit: cover
}

.q-profile-photo-img-container i {
  position: absolute;
  font-size: 2.5rem;
}

.q-photo-chooser {
  width: 100px;
  border: 2px solid rgba(0, 0, 0.12);
  margin-top: 16px;
}

.q-photo-chooser:hover {
  background-color: rgba(0, 0, 0, 0.12);
}

#file {
  display: none;
}

@media (min-width: 992px) {
  .q-profile-photo-img-container {
    max-height: 100%;
    max-width: 100px;
  }

}
</style>
