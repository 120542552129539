<template>
    <div class="q-display-flex-row   "
        style="width: 100%;background-color: var(--admin-canvas-bg);max-height: 100vh;overflow: hidden">
        <popup-dialog-element ref="popUpDialog" id="popUpDialog"></popup-dialog-element>
        <progress-overlay-element ref="q_root_overlay" id="progress_overlay"></progress-overlay-element>

        <NavigationBarComponent></NavigationBarComponent>

        <main class="q-display-flex-column" style="width: 100%;max-height: 100vh;overflow: auto;">
            <div class="q-display-flex-row " style="width: 100%;min-height: 56px !important">

                <AdminHeaderComponent></AdminHeaderComponent>
            </div>
            <div class="q-display-flex-row ">
                <router-view> </router-view>
            </div>
        </main>
    </div>
</template>

<script>
import AdminHeaderComponent from "./../../components/admin/HeaderComponent.vue"
import NavigationBarComponent from "./../../components/admin/NavigationBarComponent.vue"
//import DetailsComponent from "./../../components/admin/DetailsComponent.vue"
import { showLoadingState } from "../../../public/js/utils.js"
export default {
    name: "AdminPanel",
    components: {
        AdminHeaderComponent,
        NavigationBarComponent,
        // DetailsComponent
    },
    computed: {
        isLoadingUser() {
            return this.$store.getters["admin/isReloadingLoadingUser"];
        },
        isLoggedIn() {

            return (
                this.$store.getters["admin/isLoggedIn"] &&
                !this.$store.getters["admin/isReloadingLoadingUser"]
            );
        },
        status() {
            return this.$store.getters["user/status"];
        },
    },
    watch: {
        isLoadingUser(new_, old_) {

            if (new_ != old_) {

                this.redirectUser();
            }
        },
    },
    mounted: function () {
        console.log("is mounted  =sd=f sdf=asd f=asdf=  ")
        this.$store.dispatch("admin/reloadUser", {
            url: `${this.$store.getters["urls/adminBaseUrl"]}/checkSession/`,
            fetch: `${this.$store.getters["urls/adminBaseUrl"]}/users/me`,
        });
    },

    methods: {
        showMessage: function (text, isError) {

            this.$refs.popUpDialog.showDialog(text, isError);
        },
        redirectUser: function () {

            showLoadingState(this.isLoadingUser);
            if (this.isLoadingUser) return;

            if (!this.isLoggedIn) {

                // if (this.status == 404 || this.status == 400) {
                this.$router.push("/login/admin");
                // redirect to home

                //  } else {
                // this.showMessage("An error ocurred, please try refreshing the page", true);

                //  }

            } else {
                this.$router.push("/console/users");
            }
        },
    }
};
</script>
<style scoped></style>
