<template>
    <div :style="showCaptcha" class=" q-display-flex-column flow-center q-center">
        <div class="q-layout-container ">
            <div class="q-layout-row">
                <div class="q-col-5 q-display-flex-column" style="position: relative;margin-right: 8px;">

                    <form-input ref="captchaInput" type="text" name="text" placeHolder="Code"
                        label="Enter Result"></form-input>

                </div>
                <div class="q-col-5 q-display-flex-column" style="">
                    <span style="height: 42px;width: 100%; margin-top: 16px;padding: 0 6px 0 12px;">
                        <img ref="img" style="width: 100% !important;">
                    </span>
                </div>
                <div class="q-col-2 q-display-flex-column flow-center q-center" style="">
                    <button data-can-be-disabled type="button" @click="refresh"
                        class="refreshButton q-display-flex-column flow-center q-center" title="Refresh Captcha">
                        <i class="ri-refresh-line"></i>
                    </button>
                </div>
            </div>

        </div>
    </div>
</template>
<script>
import { showMessage, toggleDisabableElements } from "../../public/js/utils.js"

import axios from "axios";
axios.defaults.withCredentials = true;

export default {
    name: "CaptchaView",
    props: ["autoShow"],
    computed: {
        showCaptcha() {

            return this.isVisible || this.autoShow == "true" ? "display:flex" : "display:none"
        },
    },
    data() {
        return {
            isVisible: false
        };
    },
    mounted: function () {

        this.$refs.captchaInput.inputElement.removeAttribute("required")
        this.$refs.captchaInput.inputElement.removeAttribute("autocomplete")
        this.$refs.captchaInput.inputElement.setAttribute("maxLength", "4")
        if (this.autoShow == "true")
            this.refresh()
    }
    ,
    methods: {
        refresh: async function () {

            toggleDisabableElements(true)
            try {
                var result = await axios.get(`${this.$store.getters["urls/baseUrl"]}/captcha/generate`);
                this.$refs.img.setAttribute("src", `data:image/png;base64, ${result.data}`)
                toggleDisabableElements(false)
                this.$refs.captchaInput.inputElement.value = ""
            } catch (e) {
                console.log("mydf asf asf " + e)

                toggleDisabableElements(false)

                //showMessage("Failed to refresh captcha, please try reloading the  webpage", true);

            }
        },

        verify: async function () {
            console.log("is vsidfaosdf asdf asdf ")
            try {

                if (!this.$refs.captchaInput.isValid) {
                    showMessage("Please enter the verification text", true);
                    return
                }
                toggleDisabableElements(true)
                let url = `${this.$store.getters["urls/baseUrl"]}/captcha/verify/${this.$refs.captchaInput.inputValue}`
                console.log("my tulsdf sdfasdf as f " + url)
                var result = await axios.post(url);
                toggleDisabableElements(false)
                console.log("mt sdf oasf asf " + JSON.stringify(result))
                if (result.data && result.data.verfied) {
                    this.$emit("verified")
                } else {

                    showMessage("Verification failed", true);
                    this.refresh()
                }
                // user is successfully registered
            } catch (e) {
                console.log("an error correud " + JSON.stringify(e.response))

                toggleDisabableElements(false)
                this.refresh()
                showMessage("Failed to verify, please try again", true);

            }
        },
        visible: function () {
            return this.isVisible
        },
        show: function (argShow) {

            this.isVisible = argShow
            if (argShow)
                this.refresh()

        }


    }
};
</script>
<style scoped>
.q-layout-container,
.q-layout-row,
.q-col-5,
.q-col-2 {
    margin: 0 !important;
    padding: 0 !important;
}

img {
    border-radius: 8px;
    border: 1px solid rgb(0, 1, 1, .12);
    height: 100%;
}

.refreshButton {
    top: 2px;
    bottom: 0;
    background-color: rgba(39, 39, 41, 0.04) !important;
    border-radius: 8px;
    height: 42px;
    width: 42px;
}

.refreshButton i {

    font-size: 1.2em;
}
</style>
