<template>
    <div aria-modal="true" aria-label="user Details"
        class=" q-animatable elevate-5 details-container  q-display-flex-column " ref="detailsDialog">
        <progress-overlay-element ref="q_overlay"></progress-overlay-element>
        <DialogComponent @accepted="deletePreset" ref="dialog_component"></DialogComponent>

        <div class="drawer-header  q-display-flex-row q-align-stretch">
            <h3 style="margin: 0px;width: 100%;">Preview Ai Model</h3>

            <button data-can-be-disabled @click="close" class=" q-display-flex-column q-center q-flex-center-items"
                title="close">
                <i class="ri-close-circle-line q-align-self-right"></i>
            </button>

        </div>
        <div class="q-divider"></div>

        <div class="drawer-content-container">

            <div class=" q-layout-container q-progress-overlay-container content-container">


                <div class="q-layout-row  q-animatable  child-content-container  group-title-container">
                    <div
                        class="content-container q-col-sm-12 q-col-md-6 q-col-lg-6 q-col-xl-6 q-display-flex-column q-text-container">
                        <p>Details</p>
                    </div>
                </div>
                <div v-for="(detail) in presetDetails" :key="detail.key" class="q-layout-row  child-content-container">
                    <div
                        class=" content-container q-col-sm-3 q-col-md-3 q-col-lg-3 q-col-xl-3 q-display-flex-column q-text-container">
                        <h4 style="margin: 0;">{{ detail.title }}<span>:</span></h4>
                    </div>
                    <div
                        class=" content-container q-col-sm-9 q-col-md-9 q-col-lg-9 q-col-xl-9 q-display-flex-column q-text-container">
                        <p style="margin: 0;">{{ detail.value }}</p>
                    </div>
                </div>
                <!--Controls-->
                <div class="q-layout-row  child-content-container  group-title-container">
                    <div
                        class="content-container q-col-sm-12 q-col-md-6 q-col-lg-6 q-col-xl-6 q-display-flex-column q-text-container">
                        <p>Controls</p>
                    </div>
                </div>
                <div class="q-layout-row  child-content-container  button-container  ">
                    <div class="content-container q-col-6 q-display-flex-column q-text-container">
                        <button data-can-be-disabled @click="edit"
                            class=" q-button q-display-flex-row q-center q-flex-center-items "
                            style="background-color: var(--gw_primary_color);">
                            <i class="ri-pencil-line"></i>
                            <p>Edit Model</p>
                        </button>
                    </div>
                    <div class="content-container q-col-6 q-display-flex-column q-text-container">
                        <button data-can-be-disabled @click="startDeleteUser"
                            class="delete-button q-button q-display-flex-row q-center q-flex-center-items "><i
                                class="ri-delete-bin-6-line"></i>
                            <p>Delete Model</p>
                        </button>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import { showMessage, toggleDisabableElements } from "../../../public/js/utils.js"
import DialogComponent from "./DialogComponent.vue"
import axios from "axios";
axios.defaults.withCredentials = true;
export default {
    name: "EditAiModelComponent",
    components: {
        DialogComponent
    },
    computed: {
        presetName() {
            var data = this.$store.getters["current/currentAiModel"]
            if (data.name != undefined)
                return data.name
            return ""
        }
    },
    data() {
        return {
            presetDetails: [],

        }
    },
    mounted() {

    },
    methods: {
        edit: function () {
            this.$emit('edit', '')
            this.close()
        },
        show: async function () {
            console.log("is prdp fsadpf asdf asdf as f ")
            var data = this.$store.getters["current/currentAiModel"]
            this.$refs.detailsDialog.classList.add("showDrawer")
            this.loadModel(data)

        },
        close: function () {
            this.$refs.detailsDialog.classList.remove("showDrawer")
        },

        showOverlay: function (show) {
            this.$refs.q_overlay.run = show
            toggleDisabableElements(show)

        },
        startDeleteUser: async function () {
            this.$refs.dialog_component.show(undefined, { "title": "Delete", "description": "Are you sure you want to delete this Ai Model?" })
        },
        deletePreset: async function () {
            try {
                var data = this.$store.getters["current/currentAiModel"]
                if (!data || !data.objectId) {
                    showMessage("Failed to delete model", true)
                    return
                }

                if (!this.$refs.detailsDialog.classList.contains("showDrawer")) {
                    this.show()
                }
                this.showOverlay(true)
                toggleDisabableElements(true)

                var result = await axios.post(`${this.$store.getters["urls/adminBaseUrl"]}/ai/models/delete/${data.objectId}`)

                if (result.data.msg == "ok") {
                    this.close()
                    this.$emit('deleted', '')
                } else {

                    showMessage("Failed to delete model", true)
                }
                toggleDisabableElements(false)
                this.showOverlay(false)

            } catch (error) {
                console.log("my errot is sdf asdf " + error)
                this.showOverlay(false)
                toggleDisabableElements(false)
                showMessage("Failed to delete model", true)
            }
        },


        loadModel: async function () {

            try {
                this.showOverlay(true)

                this.presetDetails = []

                let resultData = this.$store.getters["current/currentAiModel"]

                if (resultData && resultData.objectId) {
                    console.log("current eleasdfa sf " + JSON.stringify(resultData))

                    for (var key in resultData) {
                        var value = resultData[key];
                        console.log("runingkeusdf asdf s fas " + key)
                        if (key === "ACL") { continue }
                        else if (key === "objectId") {
                            this.currentUserId = value
                        } else if (key === "author") {

                            var authorResult = await axios.get(`${this.$store.getters["urls/adminBaseUrl"]}/users/${value.objectId}`)

                            this.presetDetails.push({
                                "key": "",
                                "title": "Author",
                                "value": authorResult.data.username
                            })
                            this.presetDetails.push({
                                "key": "",
                                "title": "Author Id",
                                "value": authorResult.data.objectId
                            })
                        } else if (key === "aiType") {
                            console.log("df asdf asf ======  =s-dfs=dsdf=  " + JSON.stringify(value.objectId))

                            var aitype = await axios.get(`${this.$store.getters["urls/baseUrl"]}/ai/ai/${value.objectId}`)
                            console.log("my faos fasdf a " + JSON.stringify(aitype))

                            this.presetDetails.push({
                                "key": "",
                                "title": "Ai",
                                "value": aitype.data.name
                            })
                            this.presetDetails.push({
                                "key": "",
                                "title": "Ai Id",
                                "value": value.objectId
                            })
                        } else {

                            this.presetDetails.push({
                                "key": "",
                                "title": key,
                                "value": value
                            })
                        }

                    }

                    this.showOverlay(false)
                }
            } catch (error) {
                console.log("my erroasd f fpsdf asd fas f " + error)
                this.showOverlay(false)

                showMessage("Failed to fetch  model", true)
            }

        }
    }
};
</script>
<style scoped>
.button-container .content-container {
    padding-left: 8px !important;
    padding-right: 8px !important;
}

.showDrawer {
    width: 400px !important;
    opacity: 1 !important;
}

.details-container {
    position: fixed;
    background-color: white;
    height: 100vh;
    width: 0;
    right: 0;
    top: 0;
    padding: 0px;
    z-index: 99999999999;
}

.drawer-header button:hover {
    background-color: var(--nav-current)
}

.drawer-header button {

    border-radius: 32px;
    line-height: 1;
    height: 32px;
    width: 10%;
}

.drawer-header button i {
    font-size: 1.4em;
}

.drawer-header {
    height: 32px;
    padding: 16px;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 20px;
}

.q-divider {
    margin-top: 16px;
    margin-bottom: 0px;
}

.drawer-content-container {
    height: 100%;
    width: 100%;
    overflow: auto;
}

.child-content-container p,
.child-content-container h4 {
    margin: 0;
}

.child-content-container h4 {
    font-family: main_font_bold;
    font-size: .9em;
}

.group-title-container {
    background-color: var(--theme-color-navbar-bg);

}

.q-layout-row {
    width: 100%;
}

.q-button {
    border-radius: 8px;
    width: 100%;
    background-color: var(--q_red);
    color: white !important;
}

.q-button p {
    color: white;
    margin-left: 8px
}
</style>
