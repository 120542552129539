<template>
  <div class=" q-display-flex-column  q-center q-flex-center-items" style="height: 100vh;">
    <h1>Welcome</h1>

    <div class=" q-sample-nav q-display-flex-row q-center q-flex-center-items">

      <RouterLink to="/login/user">Login As User</RouterLink>
      <RouterLink to="/login/admin">Login As dmin</RouterLink>
      <RouterLink to="/register">Register</RouterLink>
    </div>
    <router-view />
  </div>
</template>

<script>
import axios from 'axios'
//import { authorizationCode } from 'axios-oauth-client'
//const qs = require('querystring');
export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
  methods: {

    loginAdmin: async function () {
      var redirect = `${this.$store.getters["urls/baseUrl"]}/redirect`;
      var state = 232323
      var clientId = 23232323
      var scope = "scope:console"
      var response_type = "grant"
      var mode = 0
      var url = `${this.$store.getters["urls/baseUrl"]}/oauth/authorize/?state=${state}&client_id=${clientId}&redirect_uri=${redirect}&scope=${scope}&response_type=${response_type}&mode=${mode}`;

      // const getAuthorizationCode = authorizationCode(
      //   axios.create(),
      //   url, // OAuth 2.0 token endpoint
      //   'CLIENT_ID',
      //   'CLIENT_SECRET',
      //   redirect // Redirect URL for your app
      // )
      // const auth = await getAuthorizationCode('AUTHORIZATION_CODE', 'OPTIONAL_SCOPES')
      // // => { "access_token": "...", "expires_in": 900, ... }
      // console.log(" ysd faso fasdf asdf " + JSON.stringify(auth))
      // const qs = require('querystring');
      // const data = { 'grant_type': 'client_credentials' };
      // const options = {
      //   method: 'POST',
      //   headers: { 'content-type': 'application/x-www-form-urlencoded' },
      //   auth: {
      //     username: 'clientId',
      //     password: 'clientSecret',
      //   },
      //   // data: qs.stringify(data),
      // }

      try {
        console.log("usdf pasd fasf " + url)
        // var result = await fetch(url, { mode: 'no-cors', redirect: 'follow' },)
        var result = await axios.get(
          url,
        );
        console.log("mysdfi asd fasdf asdf" + JSON.stringify(result))
        window.location = result.data.url
      } catch (err) {
        console.log("muad foaspf asdf asf " + err)
        console.log("error = " + err);

      }
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.q-sample-nav a {
  margin: 16px;
  margin-left: 32px;
  margin-right: 32px;
  color: white;
  background-color: var(--gw_primary_color);
  padding-right: 16px;
  padding-left: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  border-radius: 8px;

}

h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}
</style>
