<template>
    <div :style="showCaptcha" class=" q-display-flex-column flow-center q-center">
        <div class="q-layout-container ">
            <div class="q-layout-row">
                <div class="q-col-8 q-display-flex-column" style="position: relative;">

                    <form-input ref="captchaInput" type="text" name="text" placeHolder="Enter Code"
                        label="Enter Code"></form-input>

                </div>

                <div class="q-col-4 q-display-flex-column q-center" style="">
                    <button data-can-be-disabled type="button" @click="checkForCaptcha" style="align-self: flex-end;"
                        class="refreshButton q-display-flex-column flow-center q-center" title="Request Code">
                        Send Code
                    </button>
                </div>
            </div>

        </div>
    </div>
</template>
<script>
import { showMessage, toggleDisabableElements } from "../../public/js/utils.js"

import axios from "axios";
axios.defaults.withCredentials = true;

export default {
    name: "PhoneVerificationView",
    props: ["autoShow"],
    computed: {
        showCaptcha() {
            return this.isVisible || this.autoShow == "true" ? "display:flex" : "display:none"
        },
    },
    data() {
        return {
            isVisible: false
        };
    },
    mounted: function () {

        this.$refs.captchaInput.inputElement.removeAttribute("required")
        this.$refs.captchaInput.inputElement.removeAttribute("autocomplete")
        this.$refs.captchaInput.inputElement.setAttribute("maxLength", "4")

    }
    ,
    methods: {
        checkForCaptcha: function () {
            this.$emit("checkCaptcha")
        },
        requestCode: async function (phone) {
            if (phone === "") {
                showMessage("Please enter your mobile phone number", true);
                return
            }
            console.log("msduf asdfasdf " + phone)
            toggleDisabableElements(true)
            try {
                var result = await axios.post(`${this.$store.getters["urls/baseUrl"]}/verification/send`, { "phone": phone });
                if (result.data.msg === "ok") {
                    toggleDisabableElements(false)
                }
            } catch (e) {
                console.log(" sdf asdf a" + JSON.stringify(e.response))
                if (e.response) {
                    console.log("mydf asf asf " + e)
                    toggleDisabableElements(false)

                    showMessage("Failed to send verification code", true);
                }
            }
        },
        getCode: function () {
            if (!this.$refs.captchaInput.isValid) {
                showMessage("Please enter the sms code", true);
                return ""
            }
            console.log("musd fasdf asdf " + this.$refs.captchaInput.inputValue)
            return this.$refs.captchaInput.inputValue;
        },
        // verify: async function () {
        //     try {


        //         toggleDisabableElements(true)
        //         var result = await axios.post(`${this.$store.getters["urls/baseUrl"]}/verification/verify`, {
        //             "code": this.$refs.captchaInput.inputValue
        //         });
        //         toggleDisabableElements(false)
        //         if (result.data && result.data.verfied) {
        //             this.$emit("verified")
        //         } else {
        //             this.$emit("denied")
        //             showMessage("Phone verification failed, please try again", true);
        //             this.refresh()
        //         }
        //         // user is successfully registered
        //     } catch (e) {

        //         console.log("my easdf asd f" + e)
        //         if (e.response) {
        //             this.$emit("denied")
        //             toggleDisabableElements(false)
        //             showMessage("Failed to verify, please try again", true);
        //         }
        //     }
        // },
        visible: function () {
            return this.isVisible
        },
        show: function (argShow) {
            this.isVisible = argShow

        }

    }
};
</script>
<style scoped>
.q-layout-container,
.q-layout-row,
.q-col-5,
.q-col-2 {
    margin: 0 !important;
    padding: 0 !important;
}

[class^="q-col-"],
[class^="q-layout"] {
    margin: 0 !important;
    padding: 0 !important;
}

img {
    border-radius: 8px;
    border: 1px solid rgb(0, 1, 1, .12);
    height: 100%;
}

.refreshButton {
    top: 2px;
    bottom: 0;
    background-color: var(--gw_primary_color);
    border-radius: 8px;
    color: white;
    height: 40px;
    width: 90%;
    padding-left: 8px;
    padding-right: 8px
}

.refreshButton i {

    font-size: 1.2em;
}
</style>
