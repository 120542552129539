<template>
  <div class="hello">
    <popup-dialog-element ref="popUpDialog" id="popUpDialog"></popup-dialog-element>
    <progress-overlay-element ref="q_root_overlay" id="progress_overlay"></progress-overlay-element>
    <section class=" q-has-background-img q-display-flex-column flow-center q-center"
      style="min-height: 100vh; background-image: url(images/bg.jpg)">
      <div class="q-overlay q-item-blur"></div>
      <span class="q-credit-span">
        Image by
        <a
          href="https://pixabay.com/users/loganwengerphotos-708161/?utm_source=link-attribution&utm_medium=referral&utm_campaign=image&utm_content=5151605">Logan
          Wenger</a>
        from
        <a
          href="https://pixabay.com//?utm_source=link-attribution&utm_medium=referral&utm_campaign=image&utm_content=5151605">Pixabay</a>
      </span>
      <div class="q-section">
        <div class="q-layout-container q-auth-box q-progress-overlay-container" style="padding: 0 !important;">
          <div class="q-layout-row">
            <div
              class="q-col-sm-12 q-col-md-5 q-col-lg-5 q-col-xl-5 q-display-flex-column q-text-container flow-center q-center">
              <a href="/" class="q-align-sealf-left" style="margin-top: 16px;">
                <img src="images/gw_icon.webp" alt="Gowant Writer Icon" class="q-auth-icon" />
              </a>
              <h2 class="q-aligsn-self-left" style="margin-bottom: 0.3em; margin-top: 0.2em">
                登记
              </h2>

              <p class="q-aligsn-self-left" style="margin-top: 0px;">
                开始在专为您打造的应用程序上写作
              </p>

              <legal-component></legal-component>

            </div>
            <div class="q-col-sm-12 q-col-md-7 q-col-lg-7 q-col-xl-7 q-display-flex-column flow-center q-center">

              <form style="max-width: 400px;" ref="form" class="form-container" action="#" method="post">
                <form-input ref="username" type="text" name="text" placeHolder="用户名" label="用户名"></form-input>
                <form-input ref="phoneNumber" type="tel" name="tel" placeHolder="Phone number"
                  label="Phone number"></form-input>
                <form-input ref="password" type="password" name="password" placeHolder="密码" label="创建密码"></form-input>
                <form-input ref="verifypassword" type="password" name="verifyPassword" placeHolder="Verify Password"
                  label="Verify password"></form-input>
                <PhoneVerificationView @checkCaptcha="checkCaptcha" autoShow="true" ref="phoneVerification"
                  @verified="register" @denied="showCaptcha">
                </PhoneVerificationView>

                <Captcha ref="captcha" @verified="register">
                </Captcha>

                <fieldset style="margin-top: 16px;margin-bottom: 16px;">
                  <input ref="legal" type="checkbox" checked="checked" style="height:16px; width:16px"><label
                    for="confirm" style="margin-left:8px; font-size:.9em">我已经阅读并且同意<a href="#" target="_blank"
                      rel="">Gowant
                      Writer 服务条款<i aria-hidden="true"
                        class="external alternate icon ExternalLink_icon__fd-5s"></i></a></label>
                </fieldset>

                <div class=" q-form-button-container q-display-flex-column  ">

                  <button data-can-be-disabled type="submit" name="button" class="btn anchor-button   "
                    style="align-self:stretch;">创建账户</button>

                  <span class="q-auth-option q-display-flex flow-center  q-center">
                    <p>已经有帐户？ <a ref="loginBtn" href="#" data-can-be-disabled class="q-form-link"
                        style="margin-bottom: 32px;">登录.</a></p>
                  </span>
                </div>
              </form>




            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>


import { setCookie, getCookie, eraseCookie, isJsonString, showMessage, toggleDisabableElements } from "../../public/js/utils.js"
let VERIFY_COOKIE_NAME = "verifyNewUser"

import Captcha from './../components/Captcha.vue'
import LegalComponent from "./../components/LegalComponent.vue";
import PhoneVerificationView from "./../components/PhoneVerificationComponent.vue"
import axios from "axios";

export default {
  name: "RegisterView",
  props: {
    msg: String,
  },
  data() {
    return {
      userData: {}
    }
  },
  computed: {
    isLoadingUser() {
      return this.$store.getters["user/isReloadingLoadingUser"];
    },
    isLoggedIn() {

      return (
        this.$store.getters["user/isLoggedIn"] &&
        !this.$store.getters["user/isReloadingLoadingUser"]
      );
    },
  },
  watch: {
    isLoadingUser(new_, old_) {
      if (new_ != old_) {
        this.redirectUser();
      }
    },
  },

  mounted: function () {

    this.$refs.loginBtn.addEventListener("click", (event) => {
      event.preventDefault()
      let state = this.$store.getters["status/stateValue"]
      let scope = this.$store.getters["status/scope"]
      if (state != undefined && state !== "" && scope != undefined && scope !== "")
        this.$router.push({ path: "/authorize", query: { "state": this.$route.query.state, "scope": this.$route.query.scope } });
      else
        this.$router.push("/login");

      console.log(" d=sfa=s f=asdf= as=f as=f  " + state + "  " + scope + "  ")

    })
    console.log("is logged in asd asdf a " + (getCookie(VERIFY_COOKIE_NAME) == "true"))
    this.$refs.captcha.show(getCookie(VERIFY_COOKIE_NAME) == "true")

    this.$refs.form.addEventListener("submit", (event) => {
      event.preventDefault()
      this.handleRegistration()
    });
    // this.$refs.register.addEventListener("redirectTo", (e) => {
    //   this.$router.push(e.detail.path);
    // });


    this.redirectUser();
  },

  components: {
    LegalComponent,
    Captcha,
    PhoneVerificationView
  },
  methods: {
    checkCaptcha: function () {
      console.log("check apasdfa sf " + this.$refs.captcha.visible())
      // if (this.$refs.captcha.visible()) {
      //   console.log("is erfisdfgs pgsdpgd sfg ")
      //   this.$refs.captcha.verify()
      // } else {
      console.log("is dlf asldf asf " + this.$refs.phoneNumber.isValid)
      if (this.$refs.phoneNumber.isValid) {
        this.$refs.phoneVerification.requestCode(this.$refs.phoneNumber.inputValue)
      } else {
        showMessage("Please enter your phone number")
      }
      // }
    },
    handleRegistration: function () {

      const userPasswordElement = this.$refs.password
      const usernameElement = this.$refs.username
      const emailInput = this.$refs.phoneNumber
      const verifyPassword = this.$refs.verifypassword
      const termsAccepted = this.$refs.legal


      var code = this.$refs.phoneVerification.getCode()

      if (
        !userPasswordElement.isValid ||
        !usernameElement.isValid ||
        !emailInput.isValid
      ) {
        var error = "";

        if (!usernameElement.isValid) error = "请检查您的用户名";
        else if (!emailInput.isValid) error = "Please check your phone number";
        else error = "请检查您的密码";

        showMessage(error, true);
      } else if (verifyPassword.inputValue !== userPasswordElement.inputValue) {

        showMessage("Your passwords dont match", true);

      } else if (!termsAccepted.checked) {
        showMessage("请先接受服务条款", true);
      } else if (code.length < 4) {
        showMessage("You have entered a short sms code", true);

      } else {
        this.userData =
        {
          username: usernameElement.inputValue,
          phone: "+" + emailInput.inputValue,
          password: userPasswordElement.inputValue,
          verificationCode: code
        };
        if (this.$refs.captcha.visible()) {

          this.$refs.captcha.verify()
        } else {
          this.register()
        }
        //this.$refs.phoneVerification.verify()
        //this.triggerEvent("formSubmit", data);
      }
    },
    redirectUser: function () {
      if (this.$route.query.state == undefined || this.$route.query.scope == undefined || this.$route.query.state == "" || this.$route.query.scope == "") {
        if (this.$refs.q_root_overlay)
          this.$refs.q_root_overlay.run = this.isLoadingUser;

        if (this.isLoadingUser) return;

        if (this.isLoggedIn) {
          this.$router.push("/profile");
        }
      }


    },


    showCaptcha: function () {
      setCookie(VERIFY_COOKIE_NAME, "true", 7);
      this.$refs.captcha.show(true)
    },

    register: async function () {
      let userData = this.userData

      toggleDisabableElements(true);
      try {
        let state = this.$store.getters["status/stateValue"]
        let scope = this.$store.getters["status/scope"]

        let url = `${this.$store.getters["urls/baseUrl"]}/user/register`

        let isOAuthOperation = false

        if (state != undefined && state !== "" && scope != undefined && scope !== "") {
          url = `${this.$store.getters["urls/baseUrl"]}/oauth/register?state=${state}`
          isOAuthOperation = true
        }


        const result = await axios.post(url, userData)


        var data = undefined;

        toggleDisabableElements(false);
        eraseCookie(VERIFY_COOKIE_NAME);

        this.$refs.captcha.show(false)
        if (isOAuthOperation) {

          data = result.data;

          url = data.url + "?code=" + data.code + "&state=" + data.state
          // redirect 
          window.location = url;

          this.$store.dispatch("status/setCurrentState", undefined)
          this.$store.dispatch("status/setScope", undefined)
          this.$store.dispatch("user/setTempUserData", {});
        } else {
          //
          data = result.data[0]
          if (data.error !== undefined && data.error !== null) {
            // failed to login



            showMessage(data.error, true);
          } else {

            var data_ = data;
            data_.username = userData.username;
            data_.useremail = data.email;
            this.$store.dispatch("user/setUser", data_);


            this.$store.dispatch("user/setIsLoggedIn", true);
            this.$router.push("/profile");
          }
        }
        //this.$router.push("/verify");
      } catch (e) {
        this.$refs.captcha.show(true)
        toggleDisabableElements(false);

        if (e.response) {

          if (e.response.data != undefined && e.response.data.detail != undefined) {

            if (isJsonString(e.response.data.detail)) {

              var error = JSON.parse(e.response.data.detail)


              if (error.message != undefined) {

                showMessage(error.message, true);

              }
            } else {
              var details = e.response.data.detail;
              if (Array.isArray(details) && details.length > 0) {
                var item_ = details[0]

                if (item_.msg)
                  showMessage(item_.msg, true);
                else
                  showMessage("Operation failed, please try again", true);

              } else {
                showMessage("Operation failed, please try again", true);
              }
            }
          }
        } else {
          showMessage("Operation failed, please check your network and try again", true);

        }


      }

    },
  },
};
</script>
<style scoped>
.q-text-container {
  padding-right: 32px;
}

.q-layout-row {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.q-credit-span {
  position: absolute;
  top: 95%;
  font-size: 0.6em;
  z-index: 999;
}

.q-overlay {
  position: absolute;
  top: 0%;
  background-color: transparent;
  width: 100%;
  height: 100%;
}

.q-layout-container {
  padding-top: 0;
  padding-bottom: 0;
}

form {
  width: 100%;
}
</style>
