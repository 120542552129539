import { createWebHistory, createRouter } from "vue-router";
import Home from "@/views/Home.vue";
import Register from "@/views/Register.vue";
import NotFound from "@/views/404.vue";
import Profile from "@/views/Profile.vue";
import Signout from "./../views/Signout.vue";
import Login from "./../views/Login.vue";
import ResetPassword from "./../views/ResetPassword.vue";
import VerifyAccount from "./../views/VerifyAccountView.vue";
import AdminOverView from "./../views/admin/OverView.vue";
import UsersView from "./../views/admin/UsersView.vue";
import PresetsView from "./../views/admin/PresetsView.vue";
import Ai from "./../views/admin/AIView.vue";
import AiTypes from "./../views/admin/AiTypes.vue";
import Authorize from "./../views/Authorize.vue";

const routes = [
  {
    path: "/",
    component: Home
  },
  {
    path: "/register",

    component: Register
  },
  {
    path: "/profile",

    component: Profile
  },
  {
    path: "/login/:authtype",
    component: Login
  },
  {
    path: "/authorize",
    component: Authorize
  },
  {
    path: "/forgotPassword",
    component: ResetPassword
  },
  {
    path: "/signout",
    component: Signout
  },
  {
    path: "/verify",
    component: VerifyAccount
  },
  {
    path: "/:notFound",
    component: NotFound
  },
  {
    path: "/console",
    component: AdminOverView,

    children: [
      {
        path: "users",
        name: "users",
        component: UsersView
      },
      {
        path: "presets",
        name: "presets",
        component: PresetsView
      },
      {
        path: "aiModels",
        name: "AiModels",
        component: Ai
      },
      {
        path: "ai",
        name: "ai",
        component: AiTypes
      }
    ]
  }
];
const router = createRouter({
  mode: "history",
  history: createWebHistory(),
  routes
});
export default router;
