import { createApp } from "vue";
import router from "./router";
import App from "./App.vue";
import mitt from "mitt";
const emitter = mitt();
import store from "./store";
//import i18n from "./i18n.js";
//App.config.globalProperties.window = window;
const app = createApp(App);
app.config.globalProperties.emitter = emitter;

app.use(router).use(store).mount("#app");

app.config.compilerOptions.isCustomElement = (tag) => {
  return tag === "form-input" || tag === "progress-overlay-element"; // (return true)
};
