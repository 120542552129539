<template>
  <div>
    <popup-dialog-element ref="popUpDialog" id="popUpDialog"></popup-dialog-element>
    <progress-overlay-element ref="q_root_overlay" id="progress_overlay"></progress-overlay-element>
    <main>
      <span class="q-credit-span">
        Image by
        <a
          href="https://pixabay.com/users/loganwengerphotos-708161/?utm_source=link-attribution&utm_medium=referral&utm_campaign=image&utm_content=5151605">Logan
          Wenger</a>
        from
        <a
          href="https://pixabay.com//?utm_source=link-attribution&utm_medium=referral&utm_campaign=image&utm_content=5151605">Pixabay</a>
      </span>
      <section class=" q-has-background-img q-display-flex-column flow-center q-center q-grayed-backgroud" style="
        min-height: 100vh;
          overflow-y: auto;
         
        " :style="{ backgroundImage: 'url(' + require('@/assets/bg.jpg') + ')' }">
        <div class="q-overlay q-item-blur"></div>
        <div class="q-section">
          <div class="q-layout-container q-auth-box q-progress-overlay-container" style="padding: 0 !important;">
            <progress-overlay-element id="progress_overlay"></progress-overlay-element>

            <div class="q-layout-row">
              <div class="q-col-sm-12 q-col-md-12 q-col-lg-5 q-col-xl-5 q-display-flex-column flow-center q-center">
                <a href="/">
                  <img src="../assets/gw_icon.webp" class="q-auth-icon" alt="gowant writer icon" />
                </a>
                <h2 class="" style="margin-bottom: 0.3em; margin-top: 0.2em">登入</h2>

                <p style=" margin-top: 0px; font-size: 1em">欢迎回来</p>


                <legal-component></legal-component>
              </div>
              <div class="q-col-sm-12 q-col-md-12 q-col-lg-7 q-col-xl-7 q-display-flex-column flow-center q-center">
                <!--Dass Logo-->
                <form ref="form" class="q-auth-form form-container" style="max-width: 400px;" method="post" action="#">

                  <form-input ref="username" type="text" name="text" placeHolder="用户名" label="您的用户名"></form-input>
                  <form-input ref="password" type="password" name="password" placeHolder="密码" label="你的密码"></form-input>

                  <Captcha ref="captcha" @verified="signIn"></Captcha>
                  <div class="q-display-flex-column  q-form-button-container  ">
                    <a href="/forgotPassword" class="q-form-link" style="margin-bottom: 8px;">Forgot Password?</a>


                    <button data-can-be-disabled type="submit" name="button" id="submit" class="btn anchor-button   "
                      style="align-self:stretch;">Login</button>

                    <span class="q-auth-option q-display-flex flow-center  q-center">
                      <p>高文作家新手? <a id="${this._registerAnchor}" data-can-be-disabled href="/register"
                          class="q-form-link" style="margin-bottom: 32px;">创建账户</a></p>
                    </span>
                  </div>

                </form>


              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  </div>
</template>

<script>
import LegalComponent from "./../components/LegalComponent.vue";
import Captcha from './../components/Captcha.vue'
import { setCookie, getCookie, eraseCookie, isJsonString } from "../../public/js/utils.js"
import { USER_ADMIN_ROLE, USERS_DEFAULT_ROLE } from "@/gwConstants";
let VERIFY_COOKIE_NAME = "verify"

import $ from "jquery";
import axios from "axios";

axios.defaults.withCredentials = true;

export default {
  name: "loginView",
  components: {
    LegalComponent, Captcha
  },
  props: {
    msg: String,
  },
  data() {
    return {
      userData: {},
      isAdminLogin: false
    }
  },
  computed: {
    isLoadingUser() {
      return this.$store.getters["user/isReloadingLoadingUser"];
    },
    isLoggedIn() {

      return (
        this.$store.getters["user/isLoggedIn"] &&
        !this.$store.getters["user/isReloadingLoadingUser"]
      );
    },
  },
  watch: {
    isLoadingUser(new_, old_) {
      if (new_ != old_) {
        this.redirectUser();
      }
    },
  },
  mounted: function () {
    var f = this.$route.params.authtype;
    console.log("Mu aosdf asdf asf " + f)
    this.isAdminLogin = (f == USER_ADMIN_ROLE)
    if (f != USERS_DEFAULT_ROLE && f != USER_ADMIN_ROLE) {
      console.log("unknown role")
      this.$router.push("/notFound");
    }

    this.$refs.form.addEventListener("submit", (e) => {
      e.preventDefault()
      const userPasswordElement = this.$refs.password

      const userEmailElement = this.$refs.username
      if (!userEmailElement.isValid) {
        this.showMessage("请检查您的用户名。", true);
      } else if (!userPasswordElement.isValid) {
        this.showMessage("请检查您的密码", true);
      } else {
        this.userData = {
          username: userEmailElement.inputValue,
          password: userPasswordElement.inputValue
        };
        if (this.$refs.captcha.visible()) {
          this.$refs.captcha.verify()
        } else {
          this.signIn();
        }
      }
    });

    this.$refs.captcha.show(getCookie(VERIFY_COOKIE_NAME) == "true")
    this.showLoadingState(true);
    this.redirectUser();
  },
  methods: {

    signIn: async function () {

      let UserForm = new FormData();
      UserForm.append('username', this.userData.username);
      UserForm.append('password', this.userData.password);

      this.toggleDisabableElements(true);
      try {
        let url = `${this.$store.getters["urls/baseUrl"]}/user/login`;
        if (this.isAdminLogin)
          url = `${this.$store.getters["urls/baseUrl"]}/user/admin/login`

        var result = await axios.post(
          url,
          UserForm
        );

        var data = result.data;

        this.toggleDisabableElements(false);
        if (data.error !== undefined && data.error !== null) {
          // failed to login
          this.showMessage(data.error, true);
        } else {
          // user is successfully registered
          var data_ = data;
          data_.username = this.userData.username;
          data_.useremail = data.email;

          this.$store.dispatch("user/setUser", data_);

          //remove verfity cookie
          eraseCookie(VERIFY_COOKIE_NAME);

          this.$store.dispatch("user/setIsLoggedIn", true);
          if (this.isAdminLogin) { this.$router.push("/console"); } else {
            this.$router.push("/profile");
          }
          this.$refs.captcha.show(false)
        }
      } catch (e) {

        this.toggleDisabableElements(false);


        if (e.response && e.response.data && e.response.data.detail && isJsonString(e.response.data.detail)) {

          var error = JSON.parse(e.response.data.detail)
          if (error.code && error.code != 0) {
            this.showMessage(error.message, true);
            // if (error.error == "username or password incorrect.") {

            // expires in 7 days
            setCookie(VERIFY_COOKIE_NAME, "true", 7);

            this.$refs.captcha.show(true)
            //}
            return
          }
        }

        this.showMessage("Login failed, please try again", true);


      }
    },
    toggleDisabableElements: function (isDisabled) {
      var items = $("[data-can-be-disabled]");

      items.each(function () {
        if (isDisabled) $(this).attr("disabled", "disabled");
        else $(this).removeAttr("disabled");
      });
    },
    showMessage: function (text, isError) {
      this.$refs.popUpDialog.showDialog(text, isError);
    },
    showLoadingState(show) {
      this.$refs.q_root_overlay.run = show;
    },
    redirectUser: function () {

      this.showLoadingState(this.isLoadingUser);
      if (this.isLoadingUser) return;

      if (this.isLoggedIn) {
        this.$router.push("/profile");
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.q-text-container {
  padding-right: 32px;
}

.q-layout-row {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.q-credit-span {
  position: absolute;
  top: 95%;
  font-size: 0.6em;
}

.q-overlay {
  position: absolute;
  top: 0%;
  background-color: transparent;
  width: 100%;
  height: 100%;
}
</style>
