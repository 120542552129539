<template>
  <div>
    <popup-dialog-element ref="popUpDialog" id="popUpDialog"></popup-dialog-element>
    <progress-overlay-element ref="q_root_overlay" id="progress_overlay"></progress-overlay-element>
  </div>
</template>

<script>
import axios from "axios";
axios.defaults.withCredentials = true;

export default {
  name: "SignOutComponent",
  props: {
    msg: String,
  },

  mounted: function () {
    this.signout();
  },
  methods: {
    showMessage: function (text, isError) {
      this.$refs.popUpDialog.showDialog(text, isError);
    },
    signout: async function () {

      try {
        await axios.get(
          `${this.$store.getters["urls/baseUrl"]}/user/logout`);
        localStorage.clear();

        this.$store.dispatch("user/signOut");
        this.$router.push("/");

      } catch (e) {
        // this.showMessage("Failed to logout, please check your connection and try again", true)
        setTimeout(() => {
          this.$router.push("/profile");
        }, 3000);
      }

    },
  },
};
</script>
<style scoped></style>
