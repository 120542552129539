<template>
    <div>
        <popup-dialog-element ref="popUpDialog" id="popUpDialog"></popup-dialog-element>
        <progress-overlay-element ref="q_root_overlay" id="progress_overlay"></progress-overlay-element>
        <main>
            <span class="q-credit-span">
                Image by
                <a
                    href="https://pixabay.com/users/loganwengerphotos-708161/?utm_source=link-attribution&utm_medium=referral&utm_campaign=image&utm_content=5151605">Logan
                    Wenger</a>
                from
                <a
                    href="https://pixabay.com//?utm_source=link-attribution&utm_medium=referral&utm_campaign=image&utm_content=5151605">Pixabay</a>
            </span>
            <section class="q-display-flex-column flow-center q-center q-grayed-backgroud" style="
        min-height: 100vh;
          overflow-y: auto;
          background-image: url(images/bg.jpg);
        ">
                <div class="q-overlay q-item-blur"></div>
                <div class="q-section">
                    <div class="q-layout-container q-auth-box q-progress-overlay-container"
                        style="padding: 0 !important;">
                        <progress-overlay-element id="progress_overlay"></progress-overlay-element>

                        <div class="q-layout-row">
                            <!-- <div
                                class="q-col-sm-12 q-col-md-12 q-col-lg-6 q-col-xl-6 q-display-flex-column flow-center q-center">

                                <p>Gowant Writer</p>
                            </div> -->
                            <div
                                class="q-col-sm-12 q-col-md-12 q-col-lg-12 q-col-xl-12 q-display-flex-column flow-center q-center">
                                <!--Dass Logo-->
                                <a href="/">
                                    <img src="images/gw_icon.webp" class="q-auth-icon" alt="gowant writer icon" />
                                </a>
                                <form class="q-auth-form form-container" ref="form" style="max-width: 400px;"
                                    method="post" action="#">
                                    <label for="">
                                        <p style="margin-top: 0px; font-size: 1em">We have sent a 6-digit SMS
                                            verification code
                                            to your phone number</p>
                                    </label>
                                    <form-input ref="smsInputElement" type="text" name="text"
                                        placeHolder="Enter Verification code"></form-input>

                                    <div class="q-display-flex-column  q-form-button-container  ">
                                        <button data-can-be-disabled type="submit" name="button" ref="submitButton"
                                            class="btn anchor-button   " style="align-self:stretch;">Verify</button>
                                        <button data-can-be-disabled type="button" name="button" class="btn   "
                                            style="align-self:stretch;margin-top: 16px;height: 32px; border-radius: 4px;">Resend</button>

                                    </div>
                                </form>

                                <legal-component></legal-component>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    </div>
</template>

<script>
import LegalComponent from "./../components/LegalComponent.vue";
import { showMessage, toggleDisabableElements } from "../../public/js/utils.js"

import axios from "axios";
axios.defaults.withCredentials = true;
export default {
    name: "VerifyUserView",
    components: {
        LegalComponent,
    },

    computed: {
        isLoadingUser() {
            return this.$store.getters["user/isReloadingLoadingUser"];
        },
        isLoggedIn() {

            return (
                this.$store.getters["user/isLoggedIn"] &&
                !this.$store.getters["user/isReloadingLoadingUser"]
            );
        },
    },
    watch: {
        isLoadingUser(new_, old_) {
            console.log(
                "isdf oasdfo asf asdf - = = = = = =  " + new_ + "   +" + old_
            );
            if (new_ != old_) {
                console.log("is as oasfo asof aosf sdf");
                this.redirectUser();
            }
        },
    },
    mounted: function () {

        this.$refs.form.addEventListener("submit", (e) => {
            e.preventDefault()
            this.loginUser()
        })

        const element = this.$refs.smsInputElement.inputElement
        element.setAttribute("inputmode", "numeric")

        element.setAttribute("onkeypress", "return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))")
        element.setAttribute("maxlength", "6")
        element.setAttribute("autocomplete", "one-time-code")

        this.redirectUser();
    },
    methods: {
        verifyUserOnOAuth: async function (userData, state) {

            toggleDisabableElements(true);
            try {
                var result = await axios.get(
                    `${this.$store.getters["urls/baseUrl"]}/oauth/verify?code=${this.$refs.smsInputElement.inputElement.value}&state=${state}`,
                );
                toggleDisabableElements(false);
                var data = result.data;
                var url = data.url + "?code=" + data.code + "&state=" + data.state

                console.trace("my ayd asdf asf " + JSON.stringify(data) + "  " + url)
                // reset everthing
                window.location = url;

                this.$store.dispatch("status/setCurrentState", undefined)
                this.$store.dispatch("status/setScope", undefined)
                this.$store.dispatch("user/setTempUserData", {});

            } catch (e) {
                console.log("msdtyf asfasf " + JSON.stringify(e))
                toggleDisabableElements(false);
                showMessage("Verification process failed ", true)
            }
        },
        loginUser: async function () {

            let state = this.$store.getters["status/stateValue"]
            let scope = this.$store.getters["status/scope"]
            let userData = this.$store.getters["user/tempData"]

            console.log("sdf sfas " + state + "  " + scope + "  " + JSON.stringify(userData))
            if (state != undefined && state != "" && scope != undefined && scope != "") {
                console.log("is handlinf auto")
                // handline  oauth
                this.verifyUserOnOAuth(userData, state)
            } else {

                // handline none oauth

                toggleDisabableElements(true);


                console.log("  my sdif asid fasfa  = = = = = " + JSON.stringify(userData))
                // reset tempData
                this.$store.dispatch("user/setTempUserData", {});

                try {
                    let UserForm = new FormData();
                    UserForm.append('username', userData.username);
                    UserForm.append('password', userData.password);

                    var signInResult = await axios.post(
                        `${this.$store.getters["urls/baseUrl"]}/login`,
                        UserForm
                    );
                    toggleDisabableElements(false);
                    var data = signInResult.data;
                    if (data.error !== undefined && data.error !== null) {
                        // failed to login
                        console.log("faile o registe yse" + JSON.stringify(data));

                        showMessage(data.error, true);
                    } else {
                        console.log("is risoafsdf " + JSON.stringify(data));
                        var data_ = data;
                        data_.username = userData.username;
                        data_.useremail = data.email;
                        this.$store.dispatch("user/setUser", data_);

                        console.log("msdf asfo asodf asdf " + JSON.stringify(data_));
                        this.$store.dispatch("user/setIsLoggedIn", true);
                        this.$router.push("/profile");
                    }
                } catch (e) {
                    toggleDisabableElements(false);
                    showMessage("Verification process failed ", true)
                }
            }
        },

        showLoadingState(show) {
            this.$refs.q_root_overlay.run = show;
        },
        redirectUser: function () {
            console.log(
                "ismsdfaslfa sf  ************88  " +
                this.isLoggedIn +
                "  " +
                this.$refs.q_root_overlay
            );
            this.showLoadingState(this.isLoadingUser);

            let state = this.$store.getters["status/stateValue"]
            let scope = this.$store.getters["status/scope"]

            if (state != undefined && state != "" && scope != undefined && scope != "") {
                console.log("is auth redirect ")
                // handline  oauth
                return;
            } else {
                console.log("sdfasd fasdf = = = = == = = == = = " + this.isLoadingUser);
                if (this.isLoadingUser) return;

                if (this.isLoggedIn) {
                    console.log("is ready");

                    this.$router.push("/profile");

                    //setUser(foundUser);
                }
            }
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.q-text-container {
    padding-right: 32px;
}

.q-layout-row {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.verify-input {
    background-color: red;
    letter-spacing: 10px !important;
}

.q-credit-span {
    position: absolute;
    top: 95%;
    font-size: 0.6em;
}

.q-overlay {
    position: absolute;
    top: 0%;
    background-color: transparent;
    width: 100%;
    height: 100%;
}
</style>
