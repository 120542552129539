<template>
    <dialog aria-modal="true" aria-label="user Details"
        class=" q-animatable q-delay-fast elevate-5 details-container  q-display-flex-column " ref="detailsDialog">
        <progress-overlay-element ref="q_overlay"></progress-overlay-element>

        <div class="drawer-header  q-display-flex-row q-align-stretch">
            <h3 style="margin: 0px;width: 100%;">Edit Preset</h3>
            <button data-can-be-disabled @click="close" class=" q-display-flex-column q-center q-flex-center-items"
                title="close">
                <i class="ri-close-circle-line q-align-self-right"></i>
            </button>

        </div>
        <div class="q-divider"></div>

        <div class="drawer-content-container">

            <div class=" q-layout-container q-progress-overlay-container content-container">

                <div class="q-layout-row  child-content-container">
                    <form action="#" ref="form" method="post" style="width: 100%;">
                        <form-input ref="inputElement" type="text" name="text" placeHolder="Title"
                            label="Enter Preset Title"></form-input>
                        <fieldset>
                            <label for="ai">Choose type</label>
                            <div class="q-password-field">

                                <select name="actions" ref="supportedActions">
                                    <option v-for="(item) in supportedActions" :key="item.key" :value="item.name">
                                        {{ item.name }}</option>
                                </select>
                            </div>

                        </fieldset>
                        <fieldset>
                            <legend class="form-legend">Choose where this preset will be displayed</legend>
                            <div class="check-box-container" v-for="(page) in supportedPages" :key="page.key">
                                <input type="checkbox" class="checkBoxes1" name="track" id="track" :value="page.name" />
                                <label class="checkBox-label" for="track">{{ page.name }}</label>
                                <br />
                            </div>
                        </fieldset>
                        <div class=" q-form-button-container q-display-flex-column  ">
                            <button ref="createButton" data-can-be-disabled type="submit" name="button"
                                class="btn anchor-button   " style="align-self:stretch;">Edit</button>
                        </div>
                    </form>
                </div>



            </div>
        </div>
    </dialog>
</template>

<script>
import { showMessage, toggleDisabableElements } from "../../../public/js/utils.js"

import axios from "axios";
axios.defaults.withCredentials = true;
export default {
    name: "EditPresetDialog",
    components: {

    },
    data() {
        return {
            supportedActions: [
                {
                    key: "",
                    "name": "Problem",

                },
                {
                    key: "",
                    "name": "Action",

                }
            ],
            supportedPages: [
            ]
        }
    },
    mounted() {

        this.$refs.form.addEventListener("submit", e => {
            e.preventDefault()
            console.log("msdf " + e)
            this.editPreset()
        })
    },
    methods: {
        updateCheckedItems: function () {
            var data = this.$store.getters["current/currentPreset"]
            var chkBoxes = document.getElementsByClassName("checkBoxes1"); // get all check box array
            if (data) {
                if (data.sections) {

                    for (var i = 0; i < chkBoxes.length; i++) {

                        chkBoxes[i].checked = data.sections.includes(chkBoxes[i].value)
                    }
                }

                this.$refs.inputElement.inputElement.value = data.name
                var action = "Problem"
                if (data.type)
                    action = data.type

                var select = this.$refs.supportedActions
                select.querySelector(`[value=${action}]`).setAttribute("selected", "selected");
            }
        },
        getSelctedSections: function () {
            var arr = [];

            var chkBoxes = document.getElementsByClassName("checkBoxes1"); // get all check box array
            for (var i = 0; i < chkBoxes.length; i++) {
                if (chkBoxes[i].checked) { // check if checked
                    arr.push(chkBoxes[i].value);
                }
            }
            return arr
        },


        fetchSections: async function () {
            try {
                if (this.supportedPages.length == 0) {
                    this.showOverlay(true)
                    var result = await axios.get(`${this.$store.getters["urls/adminBaseUrl"]}/gw/sections`)
                    this.supportedPages = result.data
                    this.showOverlay(false)
                }
                setTimeout(() => { this.updateCheckedItems() }, 10)

            } catch (error) {
                console.log("my errpr " + error)
                this.showOverlay(false)
                showMessage("Failed to fetch pages, please try again", true)
            }


        },
        show: async function () {

            this.$refs.detailsDialog.showModal()
            this.fetchSections()
        },

        editPreset: async function () {
            try {

                var selectedSections = this.getSelctedSections()
                if (selectedSections.length == 0) {
                    showMessage("Please select where this preset will be accessed", true)
                    return
                }
                var e = this.$refs.supportedActions
                this.showOverlay(true)
                var currentPreset = this.$store.getters["current/currentPreset"]
                if (this.$refs.inputElement.inputValue === "") {
                    return;
                }
                let data = {
                    name: this.$refs.inputElement.inputValue,
                    sections: selectedSections,
                    type: e.options[e.selectedIndex].text,

                }
                var result = await axios.post(`${this.$store.getters["urls/adminBaseUrl"]}/presets/edit/${currentPreset.objectId}`, data)


                if (result.data.updatedAt != "") {
                    this.close()
                    this.$emit('edited', '')
                } else {

                    showMessage("Failed to edit preset", true)
                }

                this.showOverlay(false)

            } catch (error) {
                console.log("my errpr " + error)
                this.showOverlay(false)

                showMessage("Failed to edit preset", true)
            }

        },
        close: function () {
            var chkBoxes = document.getElementsByClassName("checkBoxes1"); // get all check box array
            for (var i = 0; i < chkBoxes.length; i++) {
                chkBoxes[i].checked = false
            }
            this.$refs.detailsDialog.close()
        },
        showOverlay: function (show) {
            this.$refs.q_overlay.run = show
            toggleDisabableElements(show)
        },


    }
};
</script>
<style scoped>
dialog {

    position: absolute;

}

.showDrawer {
    width: 400px !important;
    opacity: 1 !important;
}

.details-container {

    background-color: white;

    width: 600px;
    right: 0;
    top: 0;
    padding: 0px;

}

.drawer-header button:hover {
    background-color: var(--nav-current)
}

.drawer-header button {

    border-radius: 32px;
    line-height: 1;
    height: 32px;
    width: 10%;
}

.drawer-header button i {
    font-size: 1.4em;
}

.drawer-header {
    height: 32px;
    padding: 16px;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 20px;
}

.q-divider {
    margin-top: 16px;
    margin-bottom: 0px;
}

.drawer-content-container {
    height: 100%;
    width: 100%;
    overflow: auto;
}



.child-content-container p,
.child-content-container h4 {
    margin: 0;
}

.child-content-container h4 {
    font-family: main_font_bold;
    font-size: .9em;
}

.group-title-container {
    background-color: var(--theme-color-navbar-bg);

}

.q-layout-row {
    width: 100%;
}

.q-button {
    width: 100%;
    background-color: var(--q_red);
    color: white !important;
}

.q-button p {
    color: white;
    margin-left: 8px
}
</style>
