<template>
    <div class=" q-display-flex-column  q-center q-flex-center-items" style="width: 100%;">
        <div class="main-content-container q-display-flex-column  q-center q-flex-center-items">
            <h2 class="q-align-self-left" style="margin-top: 0; margin-bottom: 32px;">Users</h2>
            <ContentHeaderComponent :controls="controls" searchPlaceHolder="Find a user by name"
                @controlData="handleControlClick" @searchItem="handleSearch">
            </ContentHeaderComponent>

            <table>
                <tr style="background-color:transparent;border-radius: 8px;">
                    <th v-for="(header) in headers" :key="header.key">
                        {{ header.name }}
                    </th>
                    <th style="width: 32px;">

                    </th>
                </tr>
                <tr :class="currentUserId == row.objectId ? 'current-row' : ''" data-can-be-disabled
                    class="q-animatable" v-for="(row) in rowsData" :key="row.objectId">
                    <td @click="viewUserDetails($event, row)">
                        {{ row.objectId }}
                    </td>
                    <td @click="viewUserDetails($event, row)">
                        {{ row.username }}
                    </td>
                    <td @click="viewUserDetails($event, row)">
                        {{ row.phone }}
                    </td>
                    <td @click="viewUserDetails($event, row)">
                        {{ polishData(row.points) }}pts
                    </td>
                    <td @click="viewUserDetails($event, row)">
                        {{ polishData(row.usedPoints) }}pts
                    </td>
                    <td @click="viewUserDetails($event, row)">
                        0pts
                    </td>
                    <OptionsPopup @setCurrent="setCurrent" @controlData="handleRowControlClick" :controls="rowControls"
                        :rowData="row">
                    </OptionsPopup>
                </tr>
            </table>
            <ContentTableFooter ref="footerItem" :totalCount="total">
            </ContentTableFooter>
            <UserDetailsDrawer @userDeleted="loadUsers" ref="detailsDialog"></UserDetailsDrawer>
        </div>
    </div>
</template>

<script>
import ContentHeaderComponent from "../../components/admin/ContentHeaderComponent.vue"
import OptionsPopup from "../../components/admin/OptionsPopup.vue"
import ContentTableFooter from "../../components/admin/ContentTableFooter.vue"


import { showLoadingState, showMessage } from "../../../public/js/utils.js"
import UserDetailsDrawer from "../../components/admin/UserDetailsDrawer.vue"
import axios from "axios";
axios.defaults.withCredentials = true;
export default {
    name: "users-compoent",
    components: {
        UserDetailsDrawer,
        ContentHeaderComponent,
        OptionsPopup,
        ContentTableFooter
    },
    props: {
        users: [],
    },
    computed: {
        isLoadingUser() {
            return this.$store.getters["admin/isReloadingLoadingUser"];
        },
        isLoggedIn() {
            return this.$store.getters["admin/isLoggedIn"];
        },
        currentUserData() {
            return this.$store.getters["current/currentUser"];
        },
        isCurrentUser() {
            var data = this.$store.getters["current/currentUser"]


            if (data.objectId != undefined && this.currentUserId == data.objectId)
                return "current-row"
            return ""
        }


    },
    watch: {
        isLoadingUser(new_, old_) {
            if (new_ != old_) {
                this.loadUsers();
            }
        },
        currentUserData(new_, old_) {

            if (new_ != old_) {
                this.setCurrentUserId();
            }
        },

    },
    methods: {
        nextPage: function () {

            this.$refs.footerItem.generateQuery(true)
        }, polishData: function (data) {

            return data ? data : 0

        },
        prevPage: function () {
            this.$refs.footerItem.generateQuery(false)
        },

        setCurrentUserId: function () {
            this.currentUserId = this.currentUserData && this.currentUserData.objectId ? this.currentUserData.objectId : ""

        },
        handleControlClick: function (event, data) {
            if (data.key === 1) {

                //delete
                this.handleRowControlClick(undefined, { key: 0 })
            } else if (data.key === 0) {
                this.loadUsers()
            }
        },
        handleSearch: function (event, data) {

            console.trace()
            if (!data || data === "") {
                this.loadUsers()
            } else {
                this.loadUsers({
                    query: `where={"username":"${data}"}`
                })
            }
        },
        loadUsers: async function (data) {
            this.$store.dispatch("current/setCurrentUser", {})

            if (!this.isLoggedIn)
                return

            let url = `${this.$store.getters["urls/adminBaseUrl"]}/users/all/limit=${499}`
            if (data) {
                if (data.limit)
                    url = `${this.$store.getters["urls/adminBaseUrl"]}/users/all/limit=${data.limit}`
                else if (data.query)
                    url = `${this.$store.getters["urls/adminBaseUrl"]}/users/all/${data.query}`
            }

            try {
                showLoadingState(true)
                var result = await axios.get(
                    url,
                );
                showLoadingState(false)

                this.rowsData = result.data.results;

                if (data && data.isNext != undefined) {

                    if (data.isNext) {

                        this.$refs.footerItem.increamentNextPage()
                    } else {

                        this.$refs.footerItem.decreamentNextPage()
                    }
                }


            } catch (error) {
                showLoadingState(false)
                showMessage("Failed to load users", true)

            }
            this.countUsers()
        },
        countUsers: async function () {
            try {
                var result = await axios.get(
                    `${this.$store.getters["urls/adminBaseUrl"]}/users/all/limit=0&count=1`,
                );

                this.total = result.data.count;
            } catch (error) {

                showMessage("Failed to load users", true)

            }
        },
        viewUserDetails: function (event, row) {
            this.$store.dispatch("current/setCurrentUser", row)
            this.$refs.detailsDialog.show(event, row)
        },
        setCurrent: function (event, row) {

            this.$store.dispatch("current/setCurrentUser", row)
        },
        handleRowControlClick: function (event, data) {

            if (this.currentUserData.objectId) {
                if (data.key === 0) {

                    this.$refs.detailsDialog.deleteUser()
                }
            } else {
                showMessage("Please select a preset ", false)
            }
        },
    },
    mounted: function () {

        this.loadUsers();
    },
    data() {

        return {
            currentUserId: "",
            total: 0,
            rowControls: [

                {
                    "key": 0,
                    "name": "Delete",
                    "toolTip": "Delete User",

                },
            ],
            headers: [
                { "name": "ObjectId", "key": "" },
                { "name": "Username", "key": "" },
                { "name": "Phone", "key": "" },
                { "name": "Available Points", "key": "" },
                { "name": "Used points", "key": "" },
                { "name": "Recharged Points", "key": "" },
            ],
            controls: [
                {
                    "key": 0,
                    "name": "Refresh",
                    "toolTip": "Refresh Presets",
                    "icon": "ri-refresh-line"
                },

                {
                    "key": 1,
                    "name": "Delete",
                    "toolTip": "Delete Preset",
                    "icon": "ri-delete-bin-line"
                },
            ],
            rowsData: []
        }
    }
};
</script>

<style scoped>
.main-content-container {
    position: relative;
}
</style>
