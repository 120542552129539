<template>
    <div class="controls-container q-display-flex-row elevate-1" style="">
        <div class="q-display-flex-row" style="width: 100%;height: inherit;">
            <div class=" q-layout-container q-progress-overlay-container content-container">
                <div class="q-layout-row  child-content-container  group-title-container"
                    style="background-color: transparent;">

                    <div class="content-container q-col-12 q-display-flex-column q-text-container">
                        <div class="search-icon-container  q-display-flex-column  q-center q-flex-center-items">
                            <i class="ri-search-line"></i>
                        </div>
                        <form ref="form" class=" q-display-flex-row " action="#" method="post">
                            <input ref="searchInput" type="search" name="" maxlength="200" spellcheck="true"
                                autocomplete="on" id="" :placeholder="searchPlaceHolder">
                            <button type="submit" title="Search"><i class="ri-arrow-right-line"></i></button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <button @click="handleControlClick($event, data)" v-for="(data) in controls" :key="data.key"
            class=" q-display-flex-row q-center q-flex-center-items" style="width: fit-content;height: inherit;"
            :title="data.toolTip">
            <i :class="data.icon"></i>
            <span style="margin-left: 8px">{{ data.name }}</span>
        </button>

    </div>
</template>

<script>
export default {
    name: "ContentHeaderComponent",
    props: [
        "controls",
        "searchPlaceHolder"
    ],
    data() {
        return {

        }
    },
    mounted: function () {
        this.$refs.form.addEventListener('submit', (event) => {
            event.preventDefault()
            this.$emit('searchItem', event, this.$refs.searchInput.value)

        })
    },
    methods: {
        handleControlClick: function (event, data) {
            //mysdfu asdfasdf
            this.$emit('controlData', event, data)
        }

    }
};
</script>
<style scoped>
dialog {
    top: 50%;
    left: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
}

.content-container {
    padding: 0 !important;
}

.search-icon-container {
    position: absolute;
    z-index: 999;
    left: 8px;
    top: 0;
    bottom: 0;
}

button:hover {
    background-color: var(--nav-selected)
}


.child-content-container {
    padding: 0 !important;
    height: 100%;
}

.child-content-container input {
    height: 100%;
    padding-left: 32px;
    border-radius: 8px;
}

.controls-container {
    width: 100%;
    height: 42px;
    background-color: #f5f8fd;
    border-radius: 8px;
}

button {
    border-radius: 8px;
    padding-left: 8px;
    padding-right: 8px;
    margin-left: 4px;
    margin-right: 4px;
    background-color: transparent
}

form {
    height: 100%;
}

input[type="button"] {
    width: 32px;
}

form input {
    border: 0px !important;
    padding: 0;
    background-color: transparent
}
</style>
