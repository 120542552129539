import axios from "axios";

export default {
  namespaced: true,
  state: {
    user: {},
    username: "",
    useremail: "",
    photoUrl: "",
    emailVerified: false,
    isReloadingLoadingUser: false,
    isLoggedIn: false,
    status: "finished",
    tempData: {},
    phone: ""
  },
  mutations: {
    UPDATE_USER(state, payload) {
      if (payload && payload.userPhotoUrl && payload.userPhotoUrl.url) {
        // update photo url
        state.photoUrl = payload.userPhotoUrl.url;
      }

      if (payload && payload.phone) {
        console.log(",u sdpf asdf " + payload.phone);
        // user phone
        state.phone = payload.phone;
      }
      state.username = payload.username;
      state.useremail = payload.useremail;
      if (payload.emailVerified) state.emailVerified = payload.emailVerified;

      state.user = payload;
      console.log("musdf asdf as " + state.objectId);
    },
    UPDATE_LOADING_USER(state, payload) {
      state.isReloadingLoadingUser = payload;
    },
    UPDATE_LOADING_USER_LOGGED_IN(state, payload) {
      state.isLoggedIn = payload;
    },
    UPDATE_TEMP_DATA(state, payload) {
      state.tempData = payload;
    }
  },
  actions: {
    signOut(context) {
      var data = {
        username: "",
        useremail: ""
      };
      context.commit("UPDATE_USER", data);
      context.commit("UPDATE_LOADING_USER_LOGGED_IN", false);
      context.commit("UPDATE_LOADING_USER", false);
    },
    setUser(context, payload) {
      context.commit("UPDATE_USER", payload);
    },
    setIsLoggedIn(context, payload) {
      context.commit("UPDATE_LOADING_USER_LOGGED_IN", payload);
    },
    async fetchAndUpdateUser(context, payload) {
      context.commit("UPDATE_LOADING_USER", true);
      console.log("fetching user admin " + axios.defaults.withCredentials);
      try {
        var result = await axios.get(payload.fetch, { withCredentials: true });
        context.commit("UPDATE_LOADING_USER", false);
        var resultData = result.data;
        console.log("my reslt isdfafsdf as " + JSON.stringify(result));
        if (resultData.objectId !== undefined && resultData.objectId !== null) {
          console.log("s fetched " + JSON.stringify(result));
          resultData.sessionToken = context.state.sessionToken;
          resultData.useremail = resultData.email;
          context.dispatch("setUser", resultData);
          context.commit("UPDATE_LOADING_USER_LOGGED_IN", true);
        } else {
          console.warn("failed refresh user ");
        }
      } catch (e) {
        context.commit("UPDATE_LOADING_USER", false);
        console.error("error occured " + e);
        if (e.response) {
          console.log("has response ");
        } else if (e.request) {
          console.log("has reques " + e.request.status);
        } else {
          console.log("mydsfasd fasdf asf " + e.message);
        }
      }
    },
    setTempUserData(context, payload) {
      context.commit("UPDATE_TEMP_DATA", payload);
    },
    reloadUser(context, payload) {
      context.dispatch("fetchAndUpdateUser", {
        fetch: payload.fetch
      });
    }
  },
  getters: {
    user: function (state) {
      return state.user;
    },
    username: function (state) {
      return state.username;
    },
    email: function (state) {
      return state.useremail;
    },
    photoUrl: function (state) {
      return state.photoUrl;
    },
    emailVerified: function (state) {
      return state.emailVerified;
    },
    objectId: function (state) {
      return state.objectId;
    },
    isReloadingLoadingUser: function (state) {
      return state.isReloadingLoadingUser;
    },
    isLoggedIn: function (state) {
      return state.isLoggedIn;
    },
    tempData: function (state) {
      return state.tempData;
    },
    phone: function (state) {
      return state.phone;
    }
  }
};
