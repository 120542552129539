import axios from "axios";

export default {
  namespaced: true,
  state: {
    user: {},
    username: "",
    useremail: "",
    photoUrl: "",
    emailVerified: false,
    isReloadingLoadingUser: false,
    isLoggedIn: false,
    status: 0,
    tempData: {},
    phone: "",
    role: "",
    points: 0
  },
  mutations: {
    UPDATE_USER(state, payload) {
      if (payload && payload.userPhotoUrl && payload.userPhotoUrl.url) {
        // update photo url
        state.photoUrl = payload.userPhotoUrl.url;
      }

      if (payload && payload.phone) {
        // user phone
        state.phone = payload.phone;
      }

      if (payload && payload.points) {
        // user points
        state.points = payload.points;
      }
      state.username = payload.username;
      state.useremail = payload.useremail;
      if (payload.emailVerified) state.emailVerified = payload.emailVerified;

      state.user = payload;
    },
    UPDATE_LOADING_USER(state, payload) {
      state.isReloadingLoadingUser = payload;
    },
    UPDATE_LOADING_USER_LOGGED_IN(state, payload) {
      state.isLoggedIn = payload;
    },
    UPDATE_TEMP_DATA(state, payload) {
      state.tempData = payload;
    },
    UPDATE_STATUS(state, payload) {
      state.status = payload;
    }
  },
  actions: {
    signOut(context) {
      var data = {
        username: "",
        useremail: ""
      };
      context.commit("UPDATE_USER", data);
      context.commit("UPDATE_LOADING_USER_LOGGED_IN", false);
      context.commit("UPDATE_LOADING_USER", false);
    },
    setUser(context, payload) {
      context.commit("UPDATE_USER", payload);
    },
    setIsLoggedIn(context, payload) {
      context.commit("UPDATE_LOADING_USER_LOGGED_IN", payload);
    },
    async fetchAndUpdateUser(context, payload) {
      context.commit("UPDATE_LOADING_USER", true);

      try {
        var result = await axios.get(
          payload.fetch,
          {
            objectId: context.state.objectId,
            sessionToken: context.state.sessionToken
          },
          { withCredentials: true }
        );
        context.commit("UPDATE_LOADING_USER", false);

        var resultData = result.data[0];

        if (resultData.objectId !== undefined && resultData.objectId !== null) {
          resultData.sessionToken = context.state.sessionToken;
          resultData.useremail = resultData.email;
          context.dispatch("setUser", resultData);
          context.commit("UPDATE_LOADING_USER_LOGGED_IN", true);
        }

        context.commit("UPDATE_STATUS", result.status);
      } catch (e) {
        context.commit("UPDATE_LOADING_USER", false);

        if (e.response) {
          context.commit("UPDATE_STATUS", e.response.status);
        } else if (e.request) {
          context.commit("UPDATE_STATUS", e.request.status);
        } else {
          context.commit("UPDATE_STATUS", 0);
        }
      }
    },
    setTempUserData(context, payload) {
      context.commit("UPDATE_TEMP_DATA", payload);
    },
    reloadUser(context, payload) {
      context.dispatch("fetchAndUpdateUser", {
        fetch: payload.fetch
      });
    }
  },
  getters: {
    user: function (state) {
      return state.user;
    },
    username: function (state) {
      return state.username;
    },
    email: function (state) {
      return state.useremail;
    },
    photoUrl: function (state) {
      return state.photoUrl;
    },
    emailVerified: function (state) {
      return state.emailVerified;
    },
    objectId: function (state) {
      return state.objectId;
    },
    isReloadingLoadingUser: function (state) {
      return state.isReloadingLoadingUser;
    },
    isLoggedIn: function (state) {
      return state.isLoggedIn;
    },
    tempData: function (state) {
      return state.tempData;
    },
    phone: function (state) {
      return state.phone;
    },
    status: function (state) {
      return state.status;
    },
    points: function (state) {
      return state.points;
    }
  }
};
